<template>
  <!-- 密码输错五次弹框 -->
  <s-dialog
    :visible="isShowModal"
    append-to-body
  >
    <div>{{ langText.SHEIN_KEY_PWA_15800 }}</div>
    <s-button-group
      :num="2"
      style="margin: 15px 0"
      width="100%"
    >
      <s-button-group-item
        :type="['primary', 'H72PX']"
        @click="findpassFun"
      >
        {{
          htmlDecode({ text: langText.SHEIN_KEY_PWA_15801 })
        }}
      </s-button-group-item>
      <s-button-group-item
        :type="['H72PX']"
        @click="cancelPassforget"
      >
        {{
          langText.SHEIN_KEY_PWA_15236
        }}
      </s-button-group-item>
    </s-button-group>
  </s-dialog>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { defineComponent } from 'vue'
import { htmlDecode } from '@shein/common-function'
import { mapMutations } from 'vuex'
export default defineComponent({
  name: 'PasswordErrorModal',
  components: {
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false
    },
    langText: { 
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapMutations('login', [ 'changeDialogs', 'assignState' ]),
    htmlDecode,
    findpassFun() {
      this.changeDialogs({
        key: 'isShowPasswordErrorModal',
        val: { show: false },
      })
      this.$emit('forget')
      this.$evt.emit('click-sure-password-modal')
    },
    cancelPassforget() {
      this.changeDialogs({
        key: 'isShowPasswordErrorModal',
        val: { show: false },
      })
      this.$evt.emit('click-cancel-password-modal')
    },
  }
})
</script>

