<template>
  <s-dialog
    class="page__login-newPrivacyModal"
    :visible="newPrivacyModal.show"
    :append-to-body="true"
    da-expose-code="page-login-pop-expose-events"
    :immediately-render="true"
  >
    <template
      v-if="SiteUID == 'mkr'"
      #title
    >
      {{ langText.SHEIN_KEY_PWA_31879 }}
    </template>
    <template v-if="SiteUID == 'mkr'">
      <kr-privacy-common
        :language="langText"
        from="login"
        @change-handle="changeHandle"
      />
    </template>
    <div
      v-else
      @click="handleNewPrivacyClick"
      v-html="
        combineNewPrivacyLanguage({
          abt: 'yes',
          from: 'pop',
          type: newPrivacyAbtFrom,
          location: `login_privacy_pop`,
          language: langText
        })
      "
    ></div>
    <s-button-group
      :num="2"
      style="margin: 15px 0"
      width="100%"
    >
      <s-button-group-item
        :type="['primary', 'H72PX']"
        @click="agreeNewPrivacy"
      >
        {{ langText[SiteUID == 'mkr' ? 'SHEIN_KEY_PWA_15578' : 'SHEIN_KEY_PWA_18126'] }}
      </s-button-group-item>
      <s-button-group-item
        :type="['H72PX']"
        @click="cancelNewPrivacy"
      >
        {{ langText[SiteUID == 'mkr' ? 'SHEIN_KEY_PWA_27702' : 'SHEIN_KEY_PWA_18699'] }}
      </s-button-group-item>
    </s-button-group>
  </s-dialog>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { nextTick, defineComponent } from 'vue'
import { mapState, mapMutations } from 'vuex'
import {
  combineNewPrivacyLanguage,
  handleNewPrivacyClick,
  newPrivacyCache
} from '../../util'
import analysisIndex from '../../analysis/index'

const { sensorsSend } = analysisIndex
const { SiteUID } = gbCommonInfo
import KrPrivacyCommon from 'public/src/pages/components/KrPrivacyCommon/index.vue'
export default defineComponent({
  name: 'LoginNewPrivacy',
  components: {
    KrPrivacyCommon,
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
  },
  data() {
    return {
      SiteUID,
      newPrivacyAbtFrom: '',
      krPrivacy: false
    }
  },
  computed: {
    ...mapState('login', [
      'langText',
      'newPrivacyModal',
      'defaultLocation'
    ]),
  },
  watch: {
    'newPrivacyModal.show': {
      handler (v) {
        if (v) this.exposeNewPrivacyModal()
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('login', ['setNewPrivacyModal']),
    handleNewPrivacyClick,
    combineNewPrivacyLanguage,
    changeHandle ({ privacy }) {
      this.krPrivacy = privacy
    },
    exposeNewPrivacyModal() {
      const { from } = this.newPrivacyModal
      const login_type = from == 'email' ? 0 : 1
      sensorsSend('2-8-8', { login_type, type: from })
    },
    agreeNewPrivacy() {
      if (!this.krPrivacy && SiteUID == 'mkr') {
        SToast(this.langText.SHEIN_KEY_PWA_31878)
        return
      }
      const { from, cb } = this.newPrivacyModal
      this.setNewPrivacyModal({ show: false })
      const login_type = from == 'email' ? 0 : 1
      newPrivacyCache.set(
        this.defaultLocation?.forceId || this.defaultLocation?.id || ''
      )
      sensorsSend('2-8-9', { login_type, type: from })
      nextTick(() => {
        cb && cb()
      })
    },
    cancelNewPrivacy() {
      const { from } = this.newPrivacyModal
      this.setNewPrivacyModal({ show: false })
      const login_type = from == 'email' ? 0 : 1
      sensorsSend('2-8-10', { login_type, type: from })
    },
  }
})
</script>
