<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <path
      d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
      fill="#4CC764"
    />
    <path
      d="M36.6711 20.7185C36.6711 14.1474 30.0835 8.79999 21.9847 8.79999C13.886 8.79999 7.29834 14.1474 7.29834 20.7185C7.29834 26.6104 12.5233 31.5452 19.5812 32.4775C20.0597 32.5806 20.7101 32.7924 20.8751 33.2021C21.0222 33.5734 20.9713 34.155 20.9218 34.529C20.9218 34.529 20.75 35.5657 20.7128 35.7871C20.6482 36.1584 20.4172 37.2391 21.9847 36.5791C23.5522 35.9191 30.4423 31.5989 33.5237 28.0541C35.6522 25.7125 36.6711 23.3489 36.6711 20.7185Z"
      fill="white"
    />
    <path
      d="M31.7858 24.5135C31.8587 24.5135 31.9286 24.4845 31.9802 24.4329C32.0318 24.3814 32.0608 24.3114 32.0608 24.2385V23.1976C32.0608 23.1247 32.0318 23.0547 31.9802 23.0031C31.9286 22.9516 31.8587 22.9226 31.7858 22.9226H28.9821V21.8405H31.7858C31.8587 21.8405 31.9286 21.8115 31.9802 21.7599C32.0318 21.7084 32.0608 21.6384 32.0608 21.5655V20.5232C32.0608 20.4503 32.0318 20.3803 31.9802 20.3288C31.9286 20.2772 31.8587 20.2482 31.7858 20.2482H28.9821V19.1661H31.7858C31.8587 19.1661 31.9286 19.1371 31.9802 19.0856C32.0318 19.034 32.0608 18.964 32.0608 18.8911V17.8489C32.0608 17.7759 32.0318 17.706 31.9802 17.6544C31.9286 17.6028 31.8587 17.5739 31.7858 17.5739H27.6607C27.5878 17.5739 27.5179 17.6028 27.4663 17.6544C27.4147 17.706 27.3857 17.7759 27.3857 17.8489V24.2275C27.3857 24.3004 27.4147 24.3704 27.4663 24.4219C27.5179 24.4735 27.5878 24.5025 27.6607 24.5025H31.7858V24.5135Z"
      fill="#4CC764"
    />
    <path
      d="M16.5233 24.5135C16.5962 24.5135 16.6662 24.4845 16.7178 24.4329C16.7693 24.3814 16.7983 24.3114 16.7983 24.2385V23.1976C16.7983 23.1247 16.7693 23.0547 16.7178 23.0032C16.6662 22.9516 16.5962 22.9226 16.5233 22.9226H13.7183V17.8351C13.7183 17.7622 13.6893 17.6922 13.6378 17.6407C13.5862 17.5891 13.5163 17.5601 13.4433 17.5601H12.4024C12.3295 17.5601 12.2596 17.5891 12.208 17.6407C12.1564 17.6922 12.1274 17.7622 12.1274 17.8351V24.2275C12.1274 24.3004 12.1564 24.3704 12.208 24.4219C12.2596 24.4735 12.3295 24.5025 12.4024 24.5025H16.5274L16.5233 24.5135Z"
      fill="#4CC764"
    />
    <path
      d="M19.0067 17.5422H17.9658C17.8109 17.5422 17.6853 17.6678 17.6853 17.8227V24.2316C17.6853 24.3865 17.8109 24.5121 17.9658 24.5121H19.0067C19.1616 24.5121 19.2872 24.3865 19.2872 24.2316V17.8227C19.2872 17.6678 19.1616 17.5422 19.0067 17.5422Z"
      fill="#4CC764"
    />
    <path
      d="M26.0977 17.5422H25.0568C24.9838 17.5422 24.9139 17.5712 24.8623 17.6228C24.8108 17.6744 24.7818 17.7443 24.7818 17.8172V21.6246L21.8489 17.6646C21.8422 17.6541 21.8344 17.6445 21.8255 17.6357L21.809 17.6192L21.7939 17.6069H21.7857L21.7719 17.5959H21.7623L21.7471 17.5876H21.7389H21.7224H21.7128H21.6963H21.6853H21.6688H21.6578H21.6427H20.5812C20.5082 17.5876 20.4383 17.6166 20.3867 17.6682C20.3351 17.7197 20.3062 17.7897 20.3062 17.8626V24.2729C20.3062 24.3458 20.3351 24.4157 20.3867 24.4673C20.4383 24.5189 20.5082 24.5479 20.5812 24.5479H21.6234C21.6963 24.5479 21.7663 24.5189 21.8179 24.4673C21.8694 24.4157 21.8984 24.3458 21.8984 24.2729V20.427L24.834 24.3925C24.8538 24.4202 24.8785 24.4439 24.9069 24.4626L24.9248 24.4736H24.933L24.9468 24.4805H24.9605H24.9688H24.9894C25.0137 24.4866 25.0386 24.4898 25.0637 24.4901H26.099C26.172 24.4901 26.2419 24.4611 26.2935 24.4096C26.3451 24.358 26.374 24.288 26.374 24.2151V17.8227C26.3748 17.786 26.3681 17.7496 26.3546 17.7155C26.341 17.6814 26.3207 17.6504 26.295 17.6242C26.2692 17.5981 26.2385 17.5774 26.2046 17.5633C26.1707 17.5492 26.1343 17.542 26.0977 17.5422Z"
      fill="#4CC764"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LineIcon'
})
</script>
