<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
  >
    <path
      d="M22 0C16.1652 0 10.5695 2.31785 6.44365 6.44365C2.31785 10.5695 0 16.1652 0 22C0 27.8348 2.31785 33.4305 6.44365 37.5564C10.5695 41.6822 16.1652 44 22 44C27.8348 44 33.4305 41.6822 37.5564 37.5564C41.6822 33.4305 44 27.8348 44 22C44 16.1652 41.6822 10.5695 37.5564 6.44365C33.4305 2.31785 27.8348 0 22 0Z"
      fill="#4680C2"
    />
    <path
      d="M35.1312 14.9451C35.1818 14.779 35.206 14.625 35.1994 14.4875C35.1829 14.0893 34.909 13.8 34.2644 13.8H31.3846C30.6575 13.8 30.322 14.24 30.1262 14.6811C30.1262 14.6811 28.331 18.376 26.2619 20.8125C25.5909 21.5176 25.2499 21.5 24.8869 21.5C24.6922 21.5 24.2005 21.2646 24.2005 20.6189V14.9154C24.2005 14.152 24.0102 13.8 23.3953 13.8H18.2814C17.8337 13.8 17.6005 14.152 17.6005 14.5051C17.6005 15.2388 18.5883 15.4148 18.7005 17.4707V21.456C18.7005 22.424 18.5322 22.6 18.1692 22.6C17.1902 22.6 15.263 19.3 13.9727 14.9748C13.6933 14.1234 13.4139 13.8 12.6868 13.8H9.77954C8.94024 13.8 8.80054 14.2114 8.80054 14.6514C8.80054 15.4445 9.46054 19.7323 13.063 24.6625C15.6755 28.2375 19.1174 30.3 22.1644 30.3C24.0102 30.3 24.1994 29.8303 24.1994 29.0966V25.8274C24.2005 24.9463 24.4018 24.8 24.9892 24.8C25.4083 24.8 26.263 25.075 27.913 27C29.816 29.2198 30.1614 30.3 31.2526 30.3H34.1324C34.8012 30.3 35.1851 30.0195 35.2005 29.475C35.2038 29.3364 35.184 29.1813 35.1389 29.0086C34.9255 28.375 33.9465 26.8262 32.7255 25.35C32.049 24.5327 31.3813 23.7231 31.0744 23.2831C30.8687 22.996 30.7906 22.7936 30.8005 22.6C30.8104 22.3965 30.916 22.2029 31.0744 21.9323C31.0458 21.9323 34.7682 16.7062 35.1312 14.9451Z"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'VKIcon'
})
</script>
