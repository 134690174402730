<template>
  <div>
    <div 
      v-if="items.length == 1" 
      class="page__login-newUserRgiths_single" 
      :style="{ background: singleItem.bgColor}"
    >
      <img
        v-if="singleItem.iconUrl"
        :src="singleItem.iconUrl"
        :alt="singleItem.text"
      />
      <i
        v-else
        class="suiiconfont sui_icon_coupon_discount_16px_2"
        :style="{ color: singleItem.textColor }"
      ></i>
      <p :style="{ color: singleItem.textColor }">
        {{ singleItem.text }}
      </p>
    </div>
    <div
      v-if="items.length > 1"
      :style="{ background: bannerColor}"
      class="page__login-newUserRgiths_mutilple" 
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="page__login-newUserRgiths_item"
      >
        <img
          :src="item.iconUrl"
          :alt="item.mainHeading"
        />
        <h6 :style="{ 'font-weight': bannerMainBold ? 'bold' : 'normal', color: bannerTextColor }">
          {{ item.mainHeading }}
        </h6>
        <p :style="{ color: bannerTextColor }">
          {{ item.subheading }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from '@login/hooks/utils.js'

const store = useStore()

const items = ref([])
const singleItem = ref({})
const bannerColor = ref('')
const bannerTextColor = ref('')
const bannerMainBold = ref(false)

const preloadData = computed(() => store.state.login.preloadData)
const props = computed(() => {
  return preloadData.value?.cccNewUserRigths?.data?.content?.[0]?.content?.props || {}
})

const init = () => {
  items.value = props.value?.items || []
  singleItem.value = items.value?.[0] || {}
  bannerColor.value = props.value?.style?.bgColor || ''
  bannerTextColor.value = props.value?.style?.textColor || ''
  bannerMainBold.value = props.value?.style?.mainHeadingsBold == '1'
}

init()

</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NewUserRightsVue'
})
</script>

<style lang="less" scoped>
 .page__login-newUserRgiths_single {
   display: flex;
   padding: 8px 12px;
   align-items: center;
   margin-top: 17px;
   img {
     width: 22px;
     height: 22px;
   }

   i {
     // stylelint-disable-next-line
     font-size: 22px !important;
     line-height: 1;
   }

   p {
     display: 1 0 100%;
     padding-left: 8px;
     line-height: 1.2;
   }
 }

 .page__login-newUserRgiths_mutilple {
   display: flex;
   align-items: flex-start;
   padding: 8px;
   // background: #FFF6F3;
   margin-top: 8px;

   // stylelint-disable-next-line
   &>div:last-of-type::after {
     // stylelint-disable-next-line
     display: none !important;
   }
 }

 .page__login-newUserRgiths_item {
   flex: 1 1 100%;
   text-align: center;
   position: relative;
   padding: 0 5px;

   &::after {
     content: '';
     display: block;
     width: 0.5px;
     height: 22px;
     background: #DFDFDF;
     position: absolute;
     top: 50%;
     .right(0);
     transform: translateY(-50%);
   }

   img {
     /* stylelint-disable-next-line declaration-no-important */
     width: 22px !important;
     height: 22px;
     display: block;
     margin: 0 auto;
   }

   h6 {
    font-size: 12px;
     transform: scale(0.83);
     // font-weight: bold;
     padding: 4px 0 0 0;
     margin: 0;
   }

   p {
    font-size: 12px;
     transform: scale(0.83);
     margin: 0;
   }
 }
</style>
