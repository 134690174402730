<template>
  <div
    v-if="isShow"
    v-expose="{ id: '2-8-96'}"
    class="page__logn-newUserRgiths"
    :data-id="biEtra.id"
    :data-name="biEtra.name"
    :data-scene="scene"
    :data-type="type"
  >
    <NewUserRightsVue />
  </div>
</template>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed, ref } from 'vue'
import NewUserRightsVue from './NewUserRights'
import { useStore } from '@login/hooks/utils.js'
defineProps({
  type: { type: String, default: '' },
  scene: { type: String, default: '' },
})

const store = useStore()

const biEtra = ref({})

const isShow = computed(() => store.state.login.commonAbt.NewUserRights )
const preloadData = computed(() => store.state.login.preloadData)
const content = computed(() => {
  return preloadData.value?.cccNewUserRigths?.data?.content?.[0]?.content || {}
})

const init = () => {
  biEtra.value = {
    id: content.value?.id || '',
    name: content.value?.name || '',
  }
}

init()

</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NewUserRights'
})
</script>
