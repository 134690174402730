<template>
  <!-- 手机号码发送类型切换 -->
  <s-dialog
    class="page-login__popSendType"
    :visible="isShow"
    :append-to-body="true"
    :show-close="true"
    immediately-render
    da-expose-code="page-login-mergePhonePop-login"
    @close-from-icon="handleSendClose"
  >
    <p class="title">
      {{ langText.SHEIN_KEY_PWA_22296 }}
    </p>
    <s-button-group
      :num="2"
      style="margin: 28px 0 20px 0"
      width="100%"
    >
      <s-button-group-item
        v-show="sendType == 'whatsApp'"
        da-event-click="2-8-91"
        data-type="1"
        :type="['primary', 'H72PX']"
        @click="handleSendChange"
      >
        {{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'SMS') }}
      </s-button-group-item>
      <s-button-group-item
        v-show="sendType == 'sms'"
        da-event-click="2-8-91"
        data-type="2"
        :type="['primary', 'H72PX']"
        @click="handleSendChange"
      >
        {{ langText.SHEIN_KEY_PWA_22295.replace('{0}', 'whatsApp') }}
      </s-button-group-item>
      <s-button-group-item
        da-event-click="2-7-39"
        :type="['H72PX']"
        @click="handleSendResend"
      >
        {{ langText.SHEIN_KEY_PWA_14897 }}
      </s-button-group-item>
    </s-button-group>
  </s-dialog>
</template>

<script setup>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils'

defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  sendType: {
    type: String,
    default: 'sms'
  }
})

const store = useStore()
const emit = defineEmits(['resend', 'change'])

const langText = computed(() => store.state.login.langText)

const handleSendClose = () => {
  store.commit('login/changeDialogs', { key: 'sendTypeChangePop', val: { show: false } })
}

const handleSendResend = () => {
  emit('resend')
}

const handleSendChange = () => {
  emit('change')
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PhoneSendTypeIndex',
})
</script>

