<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
  >
    <rect
      x="2.29541"
      y="2.29541"
      width="40.4091"
      height="40.4091"
      rx="20.2045"
      fill="white"
      stroke="#E5E5E5"
      stroke-width="0.5"
    />
    <path
      d="M22.5 30.682V14.3184"
      stroke="#222222"
      stroke-width="1.5"
    />
    <path
      d="M14.3181 22.4998H30.6818"
      stroke="#222222"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AddIcon'
})
</script>
