<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <g clip-path="url(#clip0_332_8345)">
      <path
        d="M8.5 16.0005C12.9183 16.0005 16.5 12.4188 16.5 8.00049C16.5 3.58221 12.9183 0.000488281 8.5 0.000488281C4.08172 0.000488281 0.5 3.58221 0.5 8.00049C0.5 12.4188 4.08172 16.0005 8.5 16.0005Z"
        fill="#4CC764"
      />
      <path
        d="M13.835 7.53468C13.835 5.14518 11.4396 3.20068 8.49455 3.20068C5.54955 3.20068 3.15405 5.14518 3.15405 7.53468C3.15405 9.67718 5.05405 11.4717 7.62055 11.8107C7.79455 11.8482 8.03105 11.9252 8.09105 12.0742C8.14455 12.2092 8.12605 12.4207 8.10805 12.5567C8.10805 12.5567 8.04555 12.9337 8.03205 13.0142C8.00855 13.1492 7.92455 13.5422 8.49455 13.3022C9.06455 13.0622 11.5701 11.4912 12.6906 10.2022C13.4646 9.35069 13.835 8.49118 13.835 7.53468Z"
        fill="white"
      />
      <path
        d="M12.0585 8.91461C12.085 8.91461 12.1105 8.90408 12.1292 8.88532C12.148 8.86657 12.1585 8.84114 12.1585 8.81461V8.43611C12.1585 8.40959 12.148 8.38416 12.1292 8.3654C12.1105 8.34665 12.085 8.33611 12.0585 8.33611H11.039V7.94261H12.0585C12.085 7.94261 12.1105 7.93208 12.1292 7.91332C12.148 7.89457 12.1585 7.86914 12.1585 7.84261V7.46361C12.1585 7.43709 12.148 7.41166 12.1292 7.3929C12.1105 7.37415 12.085 7.36361 12.0585 7.36361H11.039V6.97011H12.0585C12.085 6.97011 12.1105 6.95958 12.1292 6.94082C12.148 6.92207 12.1585 6.89664 12.1585 6.87011V6.49111C12.1585 6.46459 12.148 6.43916 12.1292 6.4204C12.1105 6.40165 12.085 6.39111 12.0585 6.39111H10.5585C10.532 6.39111 10.5065 6.40165 10.4878 6.4204C10.469 6.43916 10.4585 6.46459 10.4585 6.49111V8.81061C10.4585 8.83714 10.469 8.86257 10.4878 8.88132C10.5065 8.90008 10.532 8.91061 10.5585 8.91061H12.0585V8.91461Z"
        fill="#4CC764"
      />
      <path
        d="M6.50841 8.91473C6.53493 8.91473 6.56037 8.90419 6.57912 8.88544C6.59788 8.86669 6.60841 8.84125 6.60841 8.81473V8.43623C6.60841 8.40971 6.59788 8.38427 6.57912 8.36552C6.56037 8.34676 6.53493 8.33623 6.50841 8.33623H5.48841V6.48623C5.48841 6.45971 5.47788 6.43427 5.45912 6.41552C5.44037 6.39676 5.41493 6.38623 5.38841 6.38623H5.00991C4.98339 6.38623 4.95795 6.39676 4.9392 6.41552C4.92045 6.43427 4.90991 6.45971 4.90991 6.48623V8.81073C4.90991 8.83725 4.92045 8.86269 4.9392 8.88144C4.95795 8.90019 4.98339 8.91073 5.00991 8.91073H6.50991L6.50841 8.91473Z"
        fill="#4CC764"
      />
      <path
        d="M7.41141 6.37939H7.03291C6.97658 6.37939 6.93091 6.42506 6.93091 6.48139V8.81189C6.93091 8.86823 6.97658 8.91389 7.03291 8.91389H7.41141C7.46774 8.91389 7.51341 8.86823 7.51341 8.81189V6.48139C7.51341 6.42506 7.46774 6.37939 7.41141 6.37939Z"
        fill="#4CC764"
      />
      <path
        d="M9.99003 6.3794H9.61153C9.58501 6.3794 9.55958 6.38993 9.54082 6.40868C9.52207 6.42744 9.51153 6.45287 9.51153 6.4794V7.8639L8.44503 6.42389C8.44261 6.42008 8.43975 6.41656 8.43653 6.4134L8.43053 6.4074L8.42503 6.40289H8.42203L8.41703 6.39889H8.41353L8.40803 6.39589H8.40503H8.39903H8.39553H8.38953H8.38553H8.37953H8.37553H8.37003H7.98403C7.95751 6.39589 7.93208 6.40643 7.91332 6.42518C7.89457 6.44394 7.88403 6.46937 7.88403 6.49589V8.8269C7.88403 8.85342 7.89457 8.87885 7.91332 8.89761C7.93208 8.91636 7.95751 8.9269 7.98403 8.9269H8.36303C8.38956 8.9269 8.41499 8.91636 8.43374 8.89761C8.4525 8.87885 8.46303 8.85342 8.46303 8.8269V7.42839L9.53053 8.8704C9.53771 8.88046 9.5467 8.8891 9.55703 8.89589L9.56353 8.89989H9.56653L9.57153 8.90239H9.57653H9.57953H9.58703C9.59587 8.9046 9.60493 8.90578 9.61403 8.90589H9.99053C10.0171 8.90589 10.0425 8.89536 10.0612 8.87661C10.08 8.85785 10.0905 8.83242 10.0905 8.80589V6.48139C10.0908 6.46805 10.0884 6.45479 10.0835 6.4424C10.0785 6.43 10.0712 6.41871 10.0618 6.40921C10.0524 6.3997 10.0412 6.39217 10.0289 6.38705C10.0166 6.38193 10.0034 6.37933 9.99003 6.3794Z"
        fill="#4CC764"
      />
    </g>
    <defs>
      <clipPath id="clip0_332_8345">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.5 0.000488281)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ThirdLineIcon',
})
</script>

