<template>
  <div
    v-expose="{ id: '2-8-97' }"
    class="page__login-whatsappSubscribe"
  >
    <s-checkbox
      v-model="checked"
      class="whatsappSubscribe"
      size="size16"
      da-event-click="2-8-98"
      @change="handleChange"
    >
      <p
        v-html="labelText"
      ></p>
    </s-checkbox>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { isWhatsAppDefaultChecked } from '../../util'
import { expose } from 'public/src/pages/common/analysis/directive.js'

const { WEB_CLIENT, langPath } = gbCommonInfo

export default defineComponent({
  name: 'WhatsAppSubScribe',
  components: { SCheckbox },
  directives: { expose },
  props: {
    langText: { type: Object, default: () => ({} ) },
  },
  data: () => ({
    checked: false,
  }),
  computed: {
    labelText () {
      return this.langText?.SHEIN_KEY_PWA_23496?.replace('{0}', WEB_CLIENT.toUpperCase()).replace('{1}', `<a onclick="event.stopPropagation();" href="${this.linkHref}" target="_blank">${this.langText.SHEIN_KEY_PWA_23490}</a>`) || ''
    },
    linkHref () {
      if (WEB_CLIENT == 'shein') {
        return `${langPath}/Privacy-Security-Policy-a-282.html`
      }
      return `${langPath}/Privacy-Security-Policy-a-488.html`
    }
  },
  mounted () {
    if (isWhatsAppDefaultChecked()) {
      this.checked = true
      this.handleChange()
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleChange (v) {
      this.$emit('change', this.checked)
    }
  },
  emits: ['change'],
})
</script>

<style lang="less">
.page__login-whatsappSubscribe {
  margin-top: 10px;
  .txt-l();
  .whatsappSubscribe .sui-checkbox__label-text {
      color: #767676;
      line-height: 1.1;
      .font-dpr(24px);
      .padding-l(12px);
      a {
        color: @sui_color_link;
        text-decoration: none;
      }
  }
}
</style>
