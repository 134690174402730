<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 1C33.598 1 43 10.402 43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22C1 10.402 10.402 1 22 1Z"
      fill="white"
      stroke="#E5E5E5"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.3018 19.8182H22V24.46H28.4582C28.18 25.96 27.3345 27.2309 26.0636 28.0818V31.0927H29.9418C32.2109 29.0036 33.52 25.9273 33.52 22.2727C33.52 21.4218 33.4436 20.6036 33.3018 19.8182Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 34C25.24 34 27.9564 32.9255 29.9418 31.0928L26.0636 28.0818C24.9891 28.8018 23.6145 29.2273 22 29.2273C18.8745 29.2273 16.2291 27.1164 15.2855 24.28H11.2764V27.3891C13.2509 31.3109 17.3091 34 22 34Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2855 24.28C15.0455 23.56 14.9091 22.7909 14.9091 22C14.9091 21.2091 15.0455 20.44 15.2855 19.72V16.6109H11.2764C10.4636 18.2309 10 20.0636 10 22C10 23.9364 10.4636 25.7691 11.2764 27.3891L15.2855 24.28Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 14.7727C23.7618 14.7727 25.3436 15.3782 26.5873 16.5673L30.0291 13.1255C27.9509 11.1891 25.2345 10 22 10C17.3091 10 13.2509 12.6891 11.2764 16.6109L15.2855 19.72C16.2291 16.8836 18.8745 14.7727 22 14.7727Z"
      fill="#EA4335"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GoogleIcon'
})
</script>

