<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
defineProps({
  isShowModal: { type: Boolean, default: false }
// eslint-disable-next-line semi
});
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LazyMountModal',
})
</script>
