<template>
  <s-drawer
    class="help-page_container"
    custom-class="help-page_pop"
    :visible="helpPopupData.isShow"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close-from-icon="handleCloseHelpPop"
  >
    <!-- 标题 -->
    <template
      #top
    >
      <div class="help-page_title">
        {{ langText?.SHEIN_KEY_PWA_29150 }}
      </div>
    </template>
    <div class="help-page_content">
      <p
        v-if="helpPopupData.tipsType == 'A' || helpPopupData.tipsType == 'B' "
        style="margin-bottom: 14px"
      >
        <span>{{ tipsText?.accountText }}</span>
        <span>{{ tipsText?.pointText }}</span>
        <span>{{ tipsText?.linkText }}</span>
      </p>
      <p
        v-if="helpPopupData.tipsType == 'C' "
        style="margin-bottom: 14px"
      >
        <span>{{ tipsText?.accountText }}</span>
      </p>
      <p>{{ langText?.SHEIN_KEY_PWA_29150 }}</p>
      <ul>
        <li>• {{ langText?.SHEIN_KEY_PWA_29151 }}</li>
        <li>• {{ langText?.SHEIN_KEY_PWA_29152 }}</li>
      </ul>
      <span>{{ langText?.SHEIN_KEY_PWA_29153 }}</span>
      <span
        class="help-page_resend"
        :class="{ disabled: countDownTime > 0 }"
        @click="handleResendEmail"
      >{{ langText?.SHEIN_KEY_PWA_29133 }}
        <em v-show="countDownTime > 0">({{ countDownTime }}s)</em>
      </span>
    </div>
    <template
      #footer
    >
      <div class="help-page_footer">
        <s-button
          class="help-page_btn"
          @click="handleGoShipping"
        >
          {{ langText?.SHEIN_KEY_PWA_29159 }}
        </s-button>
      </div>
    </template>
  </s-drawer>
</template>

<script setup>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { ref, computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import { sendMailForRegisterSer } from '@login/service'
import { helpPageTipsText } from '@login/utils/common'
import 'public/src/pages/login/common/initFtoken.js'

const store = useStore()
// const toast = useToast()

const countDownTime = ref(0)
const countDownTimer = ref(null)

const helpPopupData = computed(() => store.state.login.helpPageConfig)
const langText = computed(() => store.state.login.langText)
const registerSuccessPopData = computed(() => store.state.login.registerSuccessPopConfig)

const tipsText = computed(() => {
  const tipsType = helpPopupData.value.tipsType
  const registerType = helpPopupData.value.registerType
  const account = registerSuccessPopData.value.account
  const point = registerSuccessPopData.value.point
  const returnData = helpPageTipsText({ tipsType, registerType, account, langText: langText.value, point })
  return returnData
})


const handleResendEmail = async () => {
  if(countDownTime.value > 0) return
  // 极验验证
  try {
    const { status, challenge = '', risk = '' } = await window.UserGTInstance.verifyPromise()
    if (status == 'error') {
      SToast(langText.value?.SHEIN_KEY_PWA_14899)
      return
    }
    if (status == 'close') return
    const params = {
      mailType: 'register_confirm',
      challenge,
      gtRisk: risk
    }
    const { code } = await sendMailForRegisterSer(params)
    if(code == 0) {
      startCountDown(60)
    }
    if(code != 0){
      SToast(langText.value?.SHEIN_KEY_PWA_14899)
    }
  }catch (err) {
    console.log(err + '')
    SToast(langText.value?.SHEIN_KEY_PWA_14899)
  } 
}

const startCountDown  = (time) => {
  const n = time <= 0 ? 0 : time
  countDownTime.value = n
  if (countDownTimer.value) clearTimeout(countDownTimer.value)
  if (n <= 0) return
  countDownTimer.value = setTimeout(() => {
    startCountDown(n - 1)
  }, 1000)
}

const handleCloseHelpPop = () => {
  store.commit('login/setHelpPageConfig', { isShow: false })
}

const handleGoShipping = () => {
  const closeCb = registerSuccessPopData.value.cb
  store.commit('login/setHelpPageConfig', { isShow: false })
  store.commit('login/setSuccessPopConfig', { isShow: false })
  closeCb && closeCb()
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HelpPagePopup'
})
</script>

<style lang="less" scoped>
.help-page_container{
 :deep(.sui-drawer__normal-footer){
    height: 64px;
    margin-top: 0;
    line-height: 0;
  }
}
.help-page_title{
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
  color: #222;
}
.help-page_content{
  padding: 0 12px;
  margin: 12px 0;
  margin-top: 4px;
  font-size: 14px;
  color: #666;
  .help-page_resend{
    margin-left: 4px;
    color: @sui_color_link;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .disabled {
    color: #ccc;
  }
}
.help-page_footer{
  padding: 8px 12px;
  width: 100%;
  .help-page_btn{
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}
</style>
<style lang="less">
.sui-drawer {
  .help-page_pop {
    .sui-drawer__header {
      border-bottom: unset;
    }
  }
}
</style>
