<template>
  <div class="login-privacy">
    <s-button
      :width="'100%'"
      class="visitor-privacy"
      @click="toPrivacyCenter"
    >
      {{ langText.SHEIN_KEY_PWA_18433 }}
    </s-button>
    <div class="visitor-privacy-tip">
      <p>{{ langText.SHEIN_KEY_PWA_18357 }}</p>
      <p>{{ langText.SHEIN_KEY_PWA_18358 }}</p>
      <p>{{ langText?.SHEIN_KEY_PWA_18360?.replace('{}', langText.SHEIN_KEY_PWA_18507) }}</p>
    </div>
  </div>
</template>

<script>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
const { langPath, host } = gbCommonInfo

export default defineComponent({
  name: 'PrivacyCenterMode',
  components: {
    SButton,
  },
  props: {
    langText: { type: Object, default: () => ({}) },
  },
  methods: {
    toPrivacyCenter () {
      window.location.replace(`${host}${langPath}/user/privacy_request?isVisitor=true`)
    },
  }
})
</script>

<style lang="less" scoped>
.login-privacy {
  padding: 0 0.58rem;
}
.visitor-privacy {
  margin: 32/75rem 0;
}
.visitor-privacy-tip {
  color: #666;
  p:last-child {
    margin-top: 32/75rem;
  }
}
</style>
