<template>
  <s-dialog
    class="deleteModal-container"
    :visible="showDeleteAccountModal"
    :append-to-body="true"
    :immediately-render="true"
    :close-on-click-modal="false"
    @close-from-icon="handleCancelClick"
  >
    <div class="deleteModal-content_title">
      {{ langText?.SHEIN_KEY_PWA_24602 }}
    </div>
    <template #footer>
      <s-button-group hor>
        <s-button-group-item
          class="delete__btn"
          @click="handleCancelClick"
        >
          {{ langText?.SHEIN_KEY_PWA_24595 }}
        </s-button-group-item>
        <s-button-group-item
          :type="['primary']"
          class="delete__btn"
          @click="handleRemoveClick"
        >
          {{ langText?.SHEIN_KEY_PWA_24593 }}
        </s-button-group-item>
      </s-button-group>
    </template>
  </s-dialog>
</template>

<script setup>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { ref, defineExpose, watch } from 'vue'
import { exposeRetainRemovePop, clickRetainRemovePopCancel, clickRetainRemovePopRemove } from '@login/utils/loginAnalysis/multiAccount.js'

defineProps({
  langText: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['remove', 'close'])

const showDeleteAccountModal = ref(false)

watch(showDeleteAccountModal, (val)=> {
  if(val){
    exposeRetainRemovePop()
  }
}, { immediate: true })

const handleCancelClick = () => {
  emit('close')
  showDeleteAccountModal.value = false
  clickRetainRemovePopCancel()
}

const handleRemoveClick = () => {
  emit('remove')
  showDeleteAccountModal.value = false
  clickRetainRemovePopRemove()
}


defineExpose({
  showDeleteAccountModal
})
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'DeleteAccountModal',
})
</script>

<style lang="less" scoped>
.deleteModal-container{
  .deleteModal-content_title{
    padding: 0 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
  .delete__btn{
    height: 36px;
    line-height: 36px;
    :deep(.sui-button-common){
      height: 36px;
      line-height: 36px;
      font-size: 14px;
    }
  }
}
</style>
