<template>
  <div
    class="page__login_codeInput"
    :class=" errorTips ? 'error' : ''"
  >
    <p>
      <span :class="{ active: inputValue.length == 0 && codeInputFocus }">{{ inputValue.slice(0,1) }}</span>
      <span :class="{ active: inputValue.length == 1 && codeInputFocus }">{{ inputValue.slice(1,2) }}</span>
      <span :class="{ active: inputValue.length == 2 && codeInputFocus }">{{ inputValue.slice(2,3) }}</span>
      <span :class="{ active: inputValue.length == 3 && codeInputFocus }">{{ inputValue.slice(3,4) }}</span>
      <span :class="{ active: inputValue.length == 4 && codeInputFocus }">{{ inputValue.slice(4,5) }}</span>
      <span :class="{ active: inputValue.length == 5 && codeInputFocus }">{{ inputValue.slice(5,6) }}</span>
      <input
        v-model.trim="inputValue"
        type="text"
        unselectable="on"
        maxlength="6"
        @click="handleCodeClick"
        @input="handleCodeInput"
        @blur="handleCodeBlur"
      />
    </p>
    <p
      class="error-tip"
    >
      {{ errorTips }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  errorTips: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  codeInputFocus: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['click', 'update:model-value', 'blur'])

const inputValue = ref(props.modelValue)

const handleCodeClick = () => {
  emit('click')
}
const handleCodeInput = (e) => {
  emit('update:model-value', e.target.value)
}
const handleCodeBlur = () => {
  emit('blur')
}

defineExpose({
  inputValue
})
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PhoneCodeInput'
})
</script>

<style lang="less" scoped>
.page__login_codeInput{
  margin: 24px 0 16px 0;
  .error-tip{
    margin-top: 9px;
    display: block;
    font-size: 12px;
    .txt-l();
    display: block;
    color: @sui_color_unusual;
    .txt-l();
    border-bottom: 0;
  }
  p {
    position: relative;
    display: flex;
    justify-content: center;
  }
  span {
    width: 40px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 1px solid #e5e5e6;
    font-weight: bold;
    font-size: 18px;
    background: #fafafa;
    color: #222;
    position: relative;
    .margin-l(16px);

    & when (@IS_RW) {
      font-family: Adieu;
      border-radius: 2px;
    }
  }
  span:first-of-type {
    .margin-l(0);
  }
   input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: transparent;
    outline: none;
    color: transparent;
    caret-color: transparent;
    opacity: 0;
  }
  .active {
    border-color: #222;
    &::after {
      content: '';
      display: inline-block;
      height: 20px;
      width: 1px;
      background: #222;
      position: relative;
      top: 2px;
      margin: 0 2px;
      animation: page__login_PhonePop-flash 1s linear infinite;
    }
  }
  @keyframes page__login_PhonePop-flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
}
</style>
