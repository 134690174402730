<template>
  <s-drawer
    :visible="phonePasswordChange.show"
    type="full"
    :no-header="true"
    :append-to-body="true"
    class="page-login__phonePassowrdChnage"
    direction="rtl"
  >
    <s-title-nav :title="langText.SHEIN_KEY_PWA_15287">
      <template #prepend>
        <s-title-nav-item
          is-back-icon
          @click="handleEvt"
        />
      </template>
    </s-title-nav>
    <div class="content">
      <div class="input-area">
        <div class="input-item">
          <s-input
            ref="input"
            v-model="password"
            :type="isShowPassword ? 'text' : 'password'"
            :label="langText.SHEIN_KEY_PWA_15072"
            @update:model-value="handleInputChange"
          />
          <i
            class="iconfont"
            :class="isShowPassword ? 'icon-see-pass' : 'icon-pass' "
            style="color: #999999"
            @click="handleEyeBtn(1)"
          ></i>
        </div>
        <div
          v-show="!safety.hideAll"
          class="normal-info"
        >
          <div :class="{'normal-red' :safety.showAll}">
            <p :class="{'tips-greenp':safety.showFir}">
              · {{ langText.SHEIN_KEY_PWA_16140 }}
            </p>
            <p :class="{'tips-greenp':safety.showSec}">
              · {{ langText.SHEIN_KEY_PWA_16141 }}
            </p>
            <p :class="{'tips-greenp':safety.showThi}">
              · {{ langText.SHEIN_KEY_PWA_16142 }}
            </p>
            <p :class="{'tips-greenp':safety.showFour}">
              .  {{ langText.SHEIN_KEY_PWA_16892 }}
            </p>
          </div>
        </div>
        <div
          class="input-item"
          :class="confirmPasswordTip.show ? 'error' : ''"
        >
          <s-input
            ref="confirmInput"
            v-model="confirmPassword"
            :type="isShowConfirmPassword ? 'text' : 'password'"
            :label="langText.SHEIN_KEY_PWA_15595"
            @update:model-value="handleConfirmInputChange"
          />
          <i
            class="iconfont"
            :class="isShowConfirmPassword ? 'icon-see-pass' : 'icon-pass' "
            style="color: #999999"
            @click="handleEyeBtn(2)"
          ></i>
          <p
            v-show="confirmPasswordTip.show"
            class="error"
          >
            {{ confirmPasswordTip.txt }}
          </p>
        </div>
        <s-button
          style="margin-top: 20px;"
          :disabled="buttonDisabled"
          width="100%"
          :type="['primary', 'H88PX']"
          @click="handleChange"
        >
          {{ langText.SHEIN_KEY_PWA_14896 }}
        </s-button>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
import { STitleNavItem } from '@shein-aidc/sui-title-nav-item/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { passwordPattren } from '../../util'
import { resetPasswordSer } from '../../service'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { clickResetPasswordSubmit } from '@login/utils/loginAnalysis/forgetPassword.js'

let delayPasswordRankTimer = null

export default defineComponent({
  name: 'PhonePasswordChange',
  components: {
    STitleNav,
    STitleNavItem,
    SInput,
    SButton,
    SDrawer,
  },
  data: () => ({
    isShowPassword: false,
    password: '',
    passwordTip: { txt: '', show: false },
    confirmPassword: '',
    isShowConfirmPassword: false,
    confirmPasswordTip: { txt: '', show: false },
    buttonDisabled: true,
    safety: { // 密码等级
      showFir: 0,
      showSec: 0,
      showThi: 0,
      showAll: 0,
      showFour: 0,
      hideAll: 1
    },
  }),
  computed: {
    ...mapState('login', ['areaCode', 'langText', 'phonePasswordChange', 'phoneLoginCodeMode', 'commonAbt']),
  },
  methods: {
    ...mapMutations('login', ['setPhonePasswordChange', 'setForgetPassword']),
    handleEvt () {
      this.password = ''
      this.confirmPassword = ''
      this.setPhonePasswordChange({ show: false })
    },
    handleInputChange () {
      this.checkPasswordRank()
    },
    isDisableSubmitBtn () {
      if (this.safety.hideAll == 1 && this.password && this.confirmPassword) {
        this.buttonDisabled = false
      }
    },
    handleConfirmInputChange () {
      this.isDisableSubmitBtn()
      this.$refs.confirmInput.checkStatus({ valid: true, message: '' })
    },
    // 检测密码等级
    checkPasswordRank () {
      const password = this.password
      //   const rawPsd = password.replace(/\s+/g, '')

      if (password.length >= 8) {
        this.safety.showFir = 1
      } else {
        this.safety.showFir = 0
      }

      if (/[a-zA-Z]+/.test(password)) {
        this.safety.showSec = 1
      } else {
        this.safety.showSec = 0
      }
      if (/[0-9]+/.test(password)) {
        this.safety.showThi = 1
      } else {
        this.safety.showThi = 0
      }
      if (/\s/g.test(password)) {
        this.safety.showFour = 0
      } else {
        this.safety.showFour = 1
      }
      if (!passwordPattren.test(password)) {
        this.safety.showAll = 1
      }

      // 延迟检测隐藏全部选项
      if (delayPasswordRankTimer) clearTimeout(delayPasswordRankTimer)
      delayPasswordRankTimer = setTimeout(() => {
        if (!!this.safety.showFir && !!this.safety.showSec && !!this.safety.showThi && !!this.safety.showFour) {
          this.safety.hideAll = 1
        } else {
          this.safety.hideAll = 0
        }
        this.isDisableSubmitBtn()
      }, 200)
    },
    handleEyeBtn (type) {
      if (type == 1) {
        this.isShowPassword = !this.isShowPassword
        return
      }
      if (type == 2) {
        this.isShowConfirmPassword = !this.isShowConfirmPassword
        return
      }
    },
    handleChange () {
      window?.sa?.('send', { activity_name: 'click_reset_password_save' })
      if (this.password !== this.confirmPassword) {
        this.$refs.confirmInput.checkStatus({
          valid: false,
          message: this.langText.SHEIN_KEY_PWA_15592
        })
        return
      }
      const { password, confirmPassword } = this
      const { code: area_code, abbr: area_abbr } = this.areaCode
      const { code: verification_code, alias } = this.phonePasswordChange

      this.$refs.confirmInput.checkStatus({ valid: true, message: '' })
      const params = {
        area_code,
        area_abbr,
        alias,
        alias_type: 2,
        verification_code,
        password,
        password_confirm: confirmPassword,
      }
      this.requestResetPassword(params)
    },
    showInputTips (target, msg) {
      target.checkStatus({ valid: false, message: msg })
    },
    async requestResetPassword (params) {
      this.$evt.emit('event-loading', true)
      // 极验验证
      const res = await geetestChallenge(resetPasswordSer, params, 'send_message')
      if (!res) {
        this.$evt.emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.showInputTips(this.$refs.input, this.langText.SHEIN_KEY_PWA_16274)
        this.$evt.emit('event-loading', false)
        return
      }
      if (status == 'close') {
        this.$evt.emit('event-loading', false)
        return
      }
      const data = apiResData || {}
      const { code, tips } = data
      clickResetPasswordSubmit(code)

      this.$evt.emit('event-loading', false)

      if (code == 0) {
        SToast(this.langText.SHEIN_KEY_PWA_15301)
        this.setPhonePasswordChange({ show: false })
        this.setForgetPassword({ show: false })
        return
      }
      if (code == 400545) {
        this.showInputTips(this.$refs.confirmInput, tips)
        return
      }
      this.showInputTips(this.$refs.input, tips || this.langText.SHEIN_KEY_PWA_14899)
    }
  }
})
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity,selector-max-type */
.page-login {
    &__phonePassowrdChnage {
        .content {
            background: #f6f6f6;
            padding-top: 10px;
        }
        .input-item {
            position: relative;
            i {
                font-size: 24px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                .right(0px);
            }
        }
        .input-area {
            background: #fff;
            padding: 24px;
        }
        button {
            margin: 30px 0;
        }
        .normal-info {
            color: #999;
            margin: 10px 0;
            .normal-red {
                color: #E64545;
                & when (@IS_RW) {
                    color: #e34e60;
                }
                p {
                    line-height: 1.5;
                    &.tips-redp {
                    color: @sui_color_unusual;
                    }
                    &.tips-greenp {
                    color: @sui_color_safety;
                    }
                }
            }
            > p {
                line-height: 1.5;
            }
        }
    }
}
</style>
