// 通过webpack进行混淆处理
import GeeTest from 'public/src/pages/common/RiskCommon/libs/geetest/sdk.js'

// 登录注册模块初始化极验sdk，使用前请询问
// 服务端渲染不执行
if (typeof window === 'object') {
  if (!window.UserGTInstance) window.UserGTInstance = new GeeTest()
  window.UserGTInstance.getGtAbtResult()
}
export default {}
