/* global __GB_LoginModalInfo__,grecaptcha */
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { mapState, mapMutations } from 'vuex'
import {
  checkPw,
  urlRedirection,
  formatNormalErrorMessage,
  isFromShopping
} from '../util'
import { loginSer, getMemeberList } from '../service'
import { windowCacheDeleteCb } from '@shein/common-function'
import { postAddSubscribe } from 'public/src/pages/common/biz_helper/subscribe'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import analysisIndex from '../analysis/index'
import { Metric_Login_Detail_Click_Result_Total } from '@login/utils/monitorLog.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'
import { saSend } from '../analysis/util'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
const { sensorsSend, loginPageFrom } = analysisIndex

const { langPath, host, lang } = gbCommonInfo
const { GOOGLE_VERIFY_SITEKEY } =
  typeof __GB_LoginModalInfo__ === 'object' ? __GB_LoginModalInfo__ : {}

export default {
  data: () => ({
    password: '',
    passwordTip: { show: false, txt: '' },
    showRecaptcha: false,
    riskCheckInfo: { revealTip: false },
    revealTip: false,
    revealRes: {},
    riskInfo: {},
    loginGoogleRecaptcha: { id: '', token: '' },
    isShowGoogleRecaptcha: false, // 是否显示Google人机身份验证
    isShowGoogleRecaptchaErr: false, // 是否显示Google人机身份验证错误提示
    SIGNUP_SUBSCRIPTION_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_SUPPORT,
    SIGNUP_SUBSCRIPTION_NO_SUPPORT: gbCommonInfo.SIGNUP_SUBSCRIPTION_NO_SUPPORT,
  }),
  computed: {
    ...mapState('login', [
      'phoneAbt',
      'newPrivacyAbt',
      'relatedAcc',
      'newUIStates',
      'preloadData',
      'publicLocalData'
    ])
  },
  methods: {
    ...mapMutations('login', [
      'setPhoneBind',
      'setNewPrivacyModal',
      'changeDialogs',
      'setnewUIStates',
      'assignState'
    ]),
    handlePasswordInput() {
      this.passwordTip.txt = ''
    },
    handlePasswordChange() {
      this.showInputTips(this.passwordTip, '', false)
    },
    handlePasswordBlur() {
      const psd = this.password.trim()
      if (psd === '') {
        this.isFocusPsd = false
      }
      checkPw(psd, this.passwordTip)
    },
    closeEmailValidate() {
      this.isNeedValidateEmail = false
    },
    async requestEmailLogin(params) {
      this.$evt.emit('event-loading', true)
      const { isSwitch } = this.newUIStates
      isSwitch ? params.isSwitchUid = this.preloadData?.uid : ''
      // 极验验证
      const res = await geetestChallenge(loginSer, params, 'login')
      if (!res) {
        this.$evt.emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.aliasTip.show = true
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.emit('event-loading', false)
        return
      }
      if (status == 'close') {
        this.$evt.emit('event-loading', false)
        return
      }
      const response = apiResData || {}
      const { code, info, needVerify, pass_err } = response
      if (params.daId) {
        const subscribe_status = this.newUIStates.subscribe_status || ''
        const subcribe_type = subscribe_status && this.SIGNUP_SUBSCRIPTION_NO_SUPPORT ? 'default_not_subcribe' : 'default_subcribe'
        const subcribe_check = subscribe_status && params?.checkboxSubscrib ? '1' : '0'
        const saObj = {
          mode: 'email',
          code,
          acc: this.relatedAcc
        }
        subscribe_status ? Object.assign(saObj, { subcribe_type, subcribe_check }) : ''
        saSend(params.daId, saObj)
      } else {
        sensorsSend('2-8-77', { code, type: 'email' })
      }
      Metric_Login_Detail_Click_Result_Total({ account_type: 'email', sub_site: this.publicLocalData?.SiteUID || '', result: code == 0 ? 'success' : 'failed', error: code })

      if (code == 0) {
        this.setnewUIStates({ showType: 'continue', isToLogin: false, isSwitch: false })
        // remove pwa cache
        windowCacheDeleteCb({ keys: ['SHELL_HEAD', 'SHELL_BODY', 'dynamicData', 'homeDynamicData', 'campaignsDynamicData'] })
        window.appEventCenter?.emit('refreshCategoryCrowdInfo')
        try {
          localStorage.removeItem('user_need_edit_pwd')
        } catch (e) {
          // empty
        }
        const subscribe_status = this.newUIStates.subscribe_status || ''
        // 默认订阅，勾选表示不订阅
        if (params.is_subscribe == 1 && subscribe_status == 1) {
          postAddSubscribe({
            subscribe_type: '1',
            alias: params.email,
            scene_type: '32'
          })
        }
        const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'requestEmailLogin' })
        if (memberId) window?.emarsys?.pushCommend('setCustomerId', memberId)

        // 引导绑定手机号码, abt:emailloginBindPhone 开启 && 来自非购物流程 && 关联账号引导登陆中来自订单列表
        if (
          this.phoneAbt.emailloginBindPhone == 'on' &&
          !isFromShopping() &&
          this.relatedAcc.from != 'order_list'
        ) {
          this.checkAccountList(response, params.email || '')
          return
        }
        setTimeout(function () {
          urlRedirection(false, response, false, 'email')
        }, 300)
        return
      }

      if(code == 402922){
        this.$evt.emit('event-loading', false)
        riskVerifyChallenge(response, params, ({ paramsData, type }) => {
          if(type == 'error') {
            this.$evt.emit('event-loading', false)
            SToast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.$evt.emit('event-loading', false)
            return
          }
          this.requestEmailLogin(paramsData)
        })
        return
      }

      if (code == 300206) {
        // 登出
        window.location.href = `${host}${langPath}user/logout` // eslint-disable-line
        return
      }

      if (code == 400504 && (this.mode == 'email' || this.newUIStates.mode == 'email')) {
        // 密码错误
        this.$evt.emit('event-loading', false)
        this.showInputTips(this.aliasTip, this.langText.SHEIN_KEY_PWA_15588)
        if (pass_err > 3 && lang !== 'es' && !needVerify) {
          // 非es站登录密码错误超过3次
          // 展示忘记密码弹窗
          this.changeDialogs({ key: 'isShowPasswordErrorModal', val: { show: true } })
        }
        return
      }

      if (code == 400593) {
        this.$evt.emit('event-loading', false)
        this.setNewPrivacyModal({
          from: 'email',
          account: params.email,
          cb: () => {
            this.$evt.emit('event-loading', true)
            params.clause_agree = 1
            this.requestEmailLogin(params)
          }
        })
        return
      }

      if (needVerify) {
        // 需要Google验证码
        this.isShowGoogleRecaptcha = true
        this.resetGoogleRecaptcha()
        //   grecaptcha.reset() // 重设人机验证的数据
        this.isDisableSubmitBtn = true
      } else {
        this.isShowGoogleRecaptcha = false
        this.isDisableSubmitBtn = false
      }

      if (code == -404 || code == -405) {
        this.aliasTip.txt = this.langText.SHEIN_KEY_PWA_14899
        this.aliasTip.show = true
        this.$evt.emit('event-loading', false)
        // eslint-disable-next-line no-undef
        exceptionReport.geetest()
        return
      }

      if (code == 402906) {
        const { extend_info = {} } = info || {}
        const { actions = [] } = extend_info
        const actionName = actions[0]?.name || ''

        if (actionName == 'email_captcha' && actions.length == 1) {
          const aliasMark = actions[0]?.param?.email || params.email
          const alias = params.email || actions[0]?.param?.email
          const encrypt_email = actions[0]?.param?.encrypt_email || ''

          this.riskInfo = {
            aliasMark,
            risk_id: info?.risk_id,
            alias,
            encrypt_email,
            login_from: loginPageFrom(),
            cb: () => {
              Object.assign(params, { uberctx_risk_uuid: info?.risk_id })
              this.requestEmailLogin(params)
            }
          }
          setTimeout(() => {
            this.changeDialogs({
              key: 'showEmailValidate',
              val: { show: true, riskInfo: this.riskInfo }
            })
          }, 0)
          this.isDisableSubmitBtn = false
          this.$evt.emit('event-loading', false)
          return
        }
        this.$evt.emit('event-loading', false)
        if (
          this.$refs?.temporaryRef?.runRiskChalleage(
            { params, message_type: 'login_confirm', scene: 'login' },
            response,
            ({ type }) => {
              if (type == 'close') return
              this.password = ''
            }
          )
        )
          return
      }

      const errTip = formatNormalErrorMessage(response)
      // 默认错误
      this.aliasTip.txt = errTip
      this.aliasTip.show = true
      this.isShowGoogleRecaptchaErr = false
      this.$evt.emit('event-loading', false)
    },
    async checkAccountList(res, email) {
      const { account_list, subscribe_status } = await getMemeberList() || {}
      // 如果已经绑定手机号码
      if (account_list && account_list.some(v => v.alias_type == 2)) {
        return urlRedirection(false, res)
      }
      this.$evt.emit('event-loading', false)
      if(subscribe_status == 1){
        this.assignState({
          smsSubScribeCheckBox: true
        })
      }
      this.setPhoneBind({ show: true, response: res, email })
    },
    resetGoogleRecaptcha() {
      const { id } = this.loginGoogleRecaptcha
      if (id === '') {
        this.loginGoogleRecaptcha.id = grecaptcha.render(this.$refs.loginRecaptchaVnode, {
          sitekey: GOOGLE_VERIFY_SITEKEY,
          size: 'normal',
          callback: res => {
            this.isDisableSubmitBtn = false
            this.loginGoogleRecaptcha.token = res
          },
          'expired-callback': () => {
            this.isDisableSubmitBtn = true
          },
          'error-callback': () => {
            this.isDisableSubmitBtn = true
          }
        })
        this.fixIosSafariRecaptcha()
        return
      }
      grecaptcha.reset(id)
      this.fixIosSafariRecaptcha()
    },
    fixIosSafariRecaptcha (times = 0) {
      window.__fixIosSafariRecaptchaTimer = setTimeout(() => {
        if (window.__fixIosSafariRecaptchaTimer) clearTimeout(window.__fixIosSafariRecaptchaTimer)
        if (times > 15) return
    
        // 选择 iframe 元素
        const iframe = document.querySelector('iframe[src*="recaptcha/api2/bframe"]')
        if (iframe) {
          // 修改父级元素的样式
          const parent1 = iframe.parentElement
          const parent2 = parent1 ? parent1.parentElement : null

          if (parent2) {
            parent2.style.transform = 'translateZ(200000px)'
            parent2.style.zIndex = 200000
          }
        } else {
          this.fixIosSafariRecaptcha(++times)
        }
      }, 500)
    },
  }
}
