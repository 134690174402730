<template>
  <div 
    v-if="isShowMultiAccount"
    class="multi-account__main"
  >
    <ClientOnly>
      <MultiAccountHeader />
    </ClientOnly>
    <AccountList  
      ref="accountListRef"
      :isRemove="isRemove" 
    />
    <template v-if="!isRemove">
      <div>
        <div
          v-if="!isLoginStatus && accountList?.length == 1"
          class="multi-account__login"
          @click="handleAddAccount"
        >
          {{ langText.SHEIN_KEY_PWA_24598 }}
        </div>
        <div
          v-else
          class="multi-account__add_account"
          :style="{ marginTop: accountList.length >= 5 && limitCount >= 5 ? '16px' : '' }"
          @click="handleAddAccount"
        >
          <AddIcon :style="AddIconStyle" />
          {{ langText.SHEIN_KEY_PWA_24617 }}
        </div>
      </div>
      <div
        v-if="isShowRemoveBtn"
        class="multi-account__remove fsp-element"
        @click="handleRemoveAccount"
      >
        {{ langText.SHEIN_KEY_PWA_24599 }}
      </div>
    </template>
    <div
      class="multi-account__done_btn"
    >
      <s-button
        v-if="isRemove" 
        :type="['primary']"
        width="100%"
        @click="handleRemoveDone"
      >
        {{ langText.SHEIN_KEY_PWA_24594 }}
      </s-button>
    </div>
  </div>
</template>

<script setup>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { ref, computed } from 'vue'
import { useStore } from '@login/hooks/utils'
import AddIcon from '@login/components/Icon/AddIcon.vue'
import MultiAccountHeader from './components/MultiAccountHeader.vue'
import AccountList from './components/AccountList.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { clickRemoveDone, clickRemoveLink, clickGoLoginRegisterPage } from '@login/utils/loginAnalysis/multiAccount.js'
import { formatAccountList } from '@login/utils/index.js'
import { removeAccountSer } from '@login/services/multiAccount.js'

const store = useStore()

const accountListRef = ref(null)
const isRemove = ref(false)


const langText = computed(() => store.state.login.langText)
const newUIStates = computed(() => store.state.login.newUIStates)
const commonAbt = computed(() => store.state.login.commonAbt)
const publicLocalData = computed(() => store.state.login.publicLocalData)
const accountList = computed(() => {
  const accountListValue = store.state.login?.accountList
  return formatAccountList(accountListValue)
})

const isShowMultiAccount = computed(() => {
  const { isRelation } = newUIStates.value
  if(isRelation) return false
  return accountList.value.length > 0 && commonAbt.value?.AccountManager == 'on'
})

const AddIconStyle = computed(() => {
  if(publicLocalData.value?.SiteUID == 'pwar') return { marginLeft: '8px' }
  return { marginRight: '8px' }
})

const isShowRemoveBtn = computed(() => {
  const uid = store.state.login?.preloadData?.uid
  if(accountList.value?.length == 1 && uid) return false
  if(accountList.value?.length > 0) return true
  return false
})

const isLoginStatus = computed(() => store.state.login?.preloadData?.isSSRLogin)
const limitCount = computed(() => store.state.login?.preloadData?.multiAccountLimit)


const handleAddAccount = () => {
  clickGoLoginRegisterPage()
  const isSwitch = isLoginStatus.value ? true : false
  store.commit('login/setnewUIStates', { showType: 'continue', isToLogin: true, isSwitch })
}

const handleRemoveAccount = () => {
  if(!accountList.value?.length) return
  clickRemoveLink()
  if(accountList.value?.length == 1){
    accountListRef.value?.handleShowDeleteModal(accountList.value?.[0]?.uid)
    return
  }
  isRemove.value = true
}

const handleRemoveDone = () => {
  isRemove.value = false
  clickRemoveDone()
  try{
    const params = {
      historyAccountList: accountList.value
    }
    removeAccountSer(params)
  } catch (error) {
    console.log(error + '')
  }
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MultiAccountMain'
})
</script>

<style lang="less" scoped>
.multi-account__main{
  width: 100%;
  margin-bottom: 20px;
}
.multi-account__login{
  margin-top: 16px;
  width: 100%;
  font-size: 12px;
  font-family: Arial;
  text-align: center;
  color: @sui_color_link;
  cursor: pointer;
}
.multi-account__add_account{
  padding: 8px 16px;
  width: 100%;
  height: 63px;
  color: @sui_color_main;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border: .5px solid @sui_color_gray_weak1;
}
.multi-account__remove{
  position: fixed;
  left: 50%;
  bottom: 34px;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: @sui_color_gray_dark2;
}
.multi-account__done_btn{
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 24px;
}
</style>
