<template>
  <s-drawer
    class="register-success_container"
    custom-class="register-success_pop"
    :visible="isShowGuidePop"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close-from-icon="handleCloseRegisterPop('close')"
  >
    <!-- 标题 -->
    <template
      #title
    >
      <span></span>
    </template>
    <div class="register-success_content">
      <HeaderTitleVue :langText="langText" />
      <HeaderDescVue :headerData="registerDescFormat" />
      <FirstOrderCoupon
        v-if="!isLongText"
        :langText="langText"
        :configData="registerSuccessPopConfig"
      />
      <CompatibleCoupon
        v-if="isLongText"
        :langText="langText"
        :configData="registerSuccessPopConfig"
      />
      <RecommendProuduct
        :langText="langText"
        :productList="registerSuccessPopConfig?.productList"
        :configData="registerSuccessPopConfig"
      />
      <DividerVue
        v-if="isShowBottomBanner"
        :langText="langText"
      />
      <BottomBannerVue
        v-if="isShowBottomBanner"
        :langText="langText"
      />
    </div>
    <template
      #footer
    >
      <div class="register-success_footer">
        <s-button
          class="register-success_btn"
          @click="handleCloseRegisterPop('goShopping')"
        >
          {{ langText.SHEIN_KEY_PWA_29159 }}
        </s-button>
      </div>
    </template>
  </s-drawer>
</template>

<script setup>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { computed, watch } from 'vue'
import HeaderTitleVue from './components/HeaderTitle.vue'
import HeaderDescVue from './components/HeaderDesc'
import FirstOrderCoupon from './components/FirstOrderCoupon.vue'
import CompatibleCoupon from './components/CompatibleCoupon'
import RecommendProuduct from './components/RecommendProuduct'
import DividerVue from './components/Divider'
import BottomBannerVue from './components/BottomBanner'
import { registerPopTextFormat } from '@login/utils/common'
import { exposeRegistrationSuccessPop, clickRegistrationSuccessPop } from '@login/utils/loginAnalysis'
import { useStore } from '@login/hooks/utils.js'

const { appLanguage, REGISTER_LONG_TEXT } = gbCommonInfo

const store = useStore()

const isShowGuidePop = computed(() => store.state.login.registerSuccessPopConfig.isShow)
const langText = computed(() => store.state.login.langText)

const registerSuccessPopConfig = computed(() => store.state.login.registerSuccessPopConfig)

const isLongText = computed(() => {
  const type = store.state.login.registerSuccessPopConfig.couponData.type
  if(REGISTER_LONG_TEXT.includes(appLanguage) && type != 0) return true
  return false
})

const isShowBottomBanner = computed(() => {
  const from = store.state.login.loginPopConfig.from
  const bi = store.state.login.loginPopConfig.bi
  const fromList = ['cart', 'coupon', 'checkout']
  if(fromList.includes(from)) return false
  if(fromList.includes(bi)) return false
  return true
})

const registerDescFormat = computed(() => {
  const LangText = langText.value
  const registerType =  store.state.login.registerSuccessPopConfig.registerFrom
  const point = store.state.login.registerSuccessPopConfig.point
  const registerDesc = registerPopTextFormat({ LangText, registerType, point  })
  return registerDesc
})

watch(isShowGuidePop, (val) => {
  if(val){
    exposeRegistrationSuccessPop('Regisger_Popup_A')
  }
}, { immediate: true })

const handleCloseRegisterPop = (type) => {
  const closeCb = registerSuccessPopConfig.value.cb
  store.commit('login/setSuccessPopConfig', { isShow: false })
  closeCb && closeCb()
  clickRegistrationSuccessPop(type, 'Regisger_Popup_A')
}

// eslint-disable-next-line semi
;
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RegisterSuccessGuide'
})
</script>

<style lang="less" scoped>
.register-success_content{
  padding: 0 12px;
}
.register-success_container{
  :deep(.sui-drawer__normal-footer){
    height: 64px;
    margin-top: 0;
    line-height: 0;
  }
  
}
.register-success_footer{
  padding: 8px 12px;
  width: 100%;
  .register-success_btn{
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
}
</style>
<style lang="less">
.sui-drawer {
  .register-success_pop {
    .sui-drawer__header {
      border-bottom: unset;
    }
  }
}
</style>
