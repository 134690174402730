<template>
  <s-dialog
    :visible="show"
    :show-close="true"
    class="page__login-regSucModal"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="reg-suc-header">
      <i class="iconfont icon-success"></i>
      <p>{{ langText.SHEIN_KEY_PWA_16960 }}</p>
    </div>
    <div class="reg-suc-bd">
      <h6 v-if="point > 0">
        {{ langText.SHEIN_KEY_PWA_16942 }}<br /><span v-html="getPointTips"></span>
      </h6>
      <p class="sub">
        {{ langText.SHEIN_KEY_PWA_16961 }} {{ email }} {{ langText.SHEIN_KEY_PWA_16729 }}
      </p>
      <dl :class="{ 'show-tips': showTips}">
        <dt
          DA-type="syncClick"
          DA-sa-name="registersuccess_expand" 
          @click="handleShowTips"
        >
          {{ langText.SHEIN_KEY_PWA_16962 }}
          <i
            class="iconfont"
            :class="showTips ? 'icon-shang' : 'icon-down'"
          ></i>
        </dt>
        <dd><i></i><span>{{ langText.SHEIN_KEY_PWA_16730 }}</span></dd>
        <dd><i></i><span>{{ langText.SHEIN_KEY_PWA_16731 }}</span></dd>
        <dd>
          <i></i>
          <span>
            {{ langText.SHEIN_KEY_PWA_16882 }} 
            <a 
              DA-type="syncClick"
              DA-sa-name="registersuccess_resend"
              :class="{ disabled: countDownTime > 0 }"
              href="javascript:void(0)"
              @click="resend"
            >{{ langText.SHEIN_KEY_PWA_15596 }}
              <em v-show="countDownTime > 0">({{ countDownTime }}s)</em></a>
          </span>
        </dd>
      </dl>
    </div>
    <template #footer>
      <s-button 
        d-a-type="syncClick"
        d-a-action="ClickOk-EmailCheck"
        d-a-category="登录注册页"
        d-a-sa-name="registersuccess_ok" 
        :width="'100%'"
        :type="['primary']"
        @click="handleOk"
      >
        {{ langText.SHEIN_KEY_PWA_15312 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script>
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { sendMailForRegisterSer } from '../../service'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { exposeRegistrationSuccessPop } from '@login/utils/loginAnalysis'
import schttp from 'public/src/services/schttp'

export default defineComponent({
  name: 'RegSucModal',
  components: {
    SDialog,
    SButton,
  },
  props: {
    langText: { 
      type: Object,
      default: () => ({})
    },
    isShow: {
      type: Boolean,
      default: false
    },
    sucData: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    show: false,
    email: '',
    showTips: false,
    countDownTime: 0,
    countDownTimer: null,
    point: 0, // 积分
  }),
  computed: {
    getPointTips() {
      return `${this.langText?.SHEIN_KEY_PWA_23084?.replace('{0}', `<span style='font-weight:500;color:#d10000'>${this.point}</span>`)}`
    }
  },
  watch: {
    show: {
      handler: function (val) {
        if (val) {
          exposeRegistrationSuccessPop('normal_other')
        }
      },
      immediate: true
    }
  },
  created() {
    this.getPoint()
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.email = this.sucData?.email || ''
      this.show = this.isShow
      this.closeCb = this.sucData?.cb || ''
      sa('send', { activity_name: 'expose_popup_registersuccess' })
    },
    async resend () {
      if (this.countDownTime > 0) return
      this.countDownTime = 60
      this.startCountDown()
      // 极验验证
      const {
        status,
        challenge = '',
        risk = '',
      } = await window.UserGTInstance.verifyPromise()

      if (status == 'error') {
        SToast(this.langText.SHEIN_KEY_PWA_14899)
        return
      }
      if (status == 'close') return
      const params = {
        mailType: 'register_confirm',
        challenge,
        gtRisk: risk
      }
      const { code } = await sendMailForRegisterSer(params)
      if(code != 0){
        SToast(this.langText.SHEIN_KEY_PWA_14899)
      }
    },
    startCountDown (countDownTime = 60) {
      this.countDownTimer = setTimeout(() => {
        if (this.countDownTimer) clearTimeout(this.countDownTimer)
        if (this.countDownTime < 1) return
        this.countDownTime = countDownTime - 1
        this.startCountDown(this.countDownTime)
      }, 1000)
    },    
    handleOk () {
      this.show = false
      this.closeCb && this.closeCb()
    },
    handleClose () {
      this.show = false
      this.closeCb && this.closeCb()
    },
    handleShowTips () {
      this.showTips = !this.showTips
    },
    // 获取验证邮箱的积分
    async getPoint() {
      const { code, info } = await schttp({
        method: 'POST',
        url: '/api/user/points/scene/query',
        data: { scene: ['emailVerification'] }
      })
      if(code == '0' && info?.point_list?.[0]?.point) {
        this.point = info.point_list[0].point
      }
    }
  },
})
</script>

<style lang="less">
/* stylelint-disable selector-max-specificity,selector-max-type  */
.page__login-regSucModal {
    .sui-dialog__body {
        background: url('/pwa_dist/images/reg_suc_bd-9e7a5aac2w.png') no-repeat top center;
        background-size: 100% auto;
        // padding: 16px;
        position: relative;
        .icon-close {
            margin: 0;
            position: absolute;
            top: 2px;
            right: 6px;
            color: #999999;
            .font-dpr(36px);
        }
    }
    .reg-suc-header {
        padding: 6px 0;
        text-align: center;
        [class*="iconfont"] {
            color: #5EBD66;
            .font-dpr(108px);
        }
        p {
            .font-dpr(28px);
            color: #222222;
            margin-top: -8px;
        }
    }
    .reg-suc-bd {
        .font-dpr(28px);
        color: #222222;
        line-height: 1.2;
        padding-top: 28px;
        text-align: left;
        h6 {
            font-weight: bold;
            padding-bottom: 12px;
            .font-dpr(28px);
            text-align: center;
        }
        dl {
            color: #999999;
            .font-dpr(24px);
            padding: 13px 0 0 0;
        }
        dt {
            padding-bottom: 9px;
            [class*="iconfont"] {
                display: inline-block;
                .font-dpr(28px);
                position: relative;
                top: 2px;
                margin: 0;
            }
        }
        dd {
            display: none;
            list-style: disc inside none;
            word-break: break-all;
        }
        i {
            width: 3px;
            height: 3px;
            display: inline-block;
            border-radius: 50%;
            color: #ccc;
            margin: 5px 5px 0 0;
        }
        a {
            color: @sui_color_link;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            &.disabled {
                color: #ccc;
            }
        }
        dl.show-tips {
            dd { display: flex; }
        }
    }
}
</style>
