<template>
  <s-drawer
    v-model:visible="registerPrivacy.show"
    :append-to-body="true"
    :immediately-render="true"
    :show-close="false"
    :direction="'btt'"
    :close-on-click-modal="false"
    custom-class="registe-privacy_container"
  > 
    <div class="registe-privacy_close">
      <div
        class="registe-privacy_right"
        @click="handleNotNow('close')"
      >
        <i class="iconfont icon-close"></i>
      </div>
    </div>
    <div class="registe-privacy_content">
      <span class="registe-privacy_title">{{ langText.SHEIN_KEY_PWA_27699 }}</span>
      <!-- 内容 -->
      <div
        class="registe-privacy_desc"
        @click="handleNewPrivacyClick"
        v-html="registerPrivacyLanguage"
      >
      </div> 
    </div>
    
    <div class="registe-privacy_btn">
      <SButtonGroup
        width="100%"
        hor
      >
        <SButtonGroupItem
          @click="handleNotNow('notyet')"
        >
          {{ langText.SHEIN_KEY_PWA_27702 }}
        </SButtonGroupItem>
        <SButtonGroupItem
          :type="['primary']"
          @click="handleAgreePrivacy"
        >
          {{ langText.SHEIN_KEY_PWA_27701 }}
        </SButtonGroupItem>
      </SButtonGroup>
    </div>
  </s-drawer>
</template>

<script setup>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { onMounted, computed, watch, ref, nextTick } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import { handleNewPrivacyClick } from '@login/util'
import { exposePolicyPopup, clickPolicyPopup } from '@login/utils/loginAnalysis'
import { template } from '@shein/common-function'
const { IS_RW, language } = gbCommonInfo

const store = useStore()

const isRomwe = ref(IS_RW)
const langText = computed(() => store.state.login.langText)
const registerPrivacy = computed(() => store.state.login.registerPrivacyModal)

const registerPrivacyLanguage = computed(() => {
  const str = langText.value?.SHEIN_KEY_PWA_27700
  const brand = isRomwe.value ? 'ROMWE' : 'SHEIN'
  const privacyText =
    langText.value.SHEIN_KEY_PWA_15088 || language?.SHEIN_KEY_PWA_15088 || ''
  const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies" da-event-click="2-8-59" da-event-expose="2-8-60" data-location="${location}">${privacyText}</a>`
  const termsText =
    langText.value.SHEIN_KEY_PWA_15608 || language?.SHEIN_KEY_PWA_15608 || ''
  const terms = `<a href="javascript:void(0);" class="j-terms-and-conditions" da-event-click="2-8-61" da-event-expose="2-8-62" data-location="${location}">${termsText}</a>`
  return template(brand, privacy, terms, str)
})

watch(registerPrivacy, (val) => {
  if(val.show){
    exposePolicyPopup()
  }
}, { immediate: true })

const handleNotNow = (type) => {
  store.commit('login/setRegisterPrivacyModal', { show: false })
  clickPolicyPopup(type)
}

const handleAgreePrivacy = () => {
  const { cb } = store.state.login.registerPrivacyModal
  store.commit('login/setRegisterPrivacyModal', { show: false })
  nextTick(() => {
    cb && cb()
  })
  clickPolicyPopup('agree')
}


onMounted(() => {
  // eslint-disable-next-line semi
});
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RegisterPrivacyModal'
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
:deep(.j-privacy-and-cookies),
:deep(.j-terms-and-conditions) {
  text-decoration: none;
  color: @sui_color_link;
}
.registe-privacy_close{
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  .registe-privacy_right{
    transform: translateX(-5px);
    width: 12px;
    height: 12px;
    font-weight: 700;
    line-height: 16px;
    color: @sui_color_gray_light1;
    text-align: center;
  }
}
.registe-privacy_content{
  padding:8px 12px 0px 12px
}
.registe-privacy_title{
  margin-bottom: 16px;
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
}
.registe-privacy_desc{
  margin: 12px auto;
  margin-bottom: 0;
  font-size: 14px;
  color: @sui_color_gray_dark1;
}

.registe-privacy_btn{
  padding: 20px 12px;
}
  
</style>
