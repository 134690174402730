<template>
  <div class="page__login-newUI-continue">
    <continuePage
      v-if="isShowContinue"
      :codeResult="handleSendCodeCb"
    />
    <MultiAccountMain />
    <ClientOnly>
      <SLazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="newUIStates.showType == 'email'">
          <EmailPannel />
        </LazyMountModal>
      </SLazyMount>
      <SLazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="newUIStates.mode == 'phone'">
          <PhonePannel ref="phonePannel"/>
        </LazyMountModal>
      </SLazyMount>
      <SLazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="newUIStates.isToLogin">
          <LoginContinue :codeResult="handleSendCodeCb"/>
        </LazyMountModal>
      </SLazyMount>
      <SLazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="newUIStates.showType == 'encryptionEmail'">
          <EncryptionEmail />
        </LazyMountModal>
      </SLazyMount>
      <SLazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="newUIStates.showType == 'encryptionPhone'">
          <EncryptionPhone /> 
        </LazyMountModal>
      </SLazyMount>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import continuePage from './continuePage.vue'
import MultiAccountMain from '@login/views/MultiAccountMain/index.vue'
import EmailPannel from './components/email_pannel.vue'
import PhonePannel from './components/phone_pannel.vue'
import LoginContinue from './components/LoginContinue.vue'
import EncryptionEmail from './components/EncryptionEmail.vue' 
import EncryptionPhone from './components/EncryptionPhone.vue'
import useContinueHook from './hooks/useContinueHook.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import LazyMountModal from '@lib/LazyMountModal/index.vue'

const { newUIStates, isShowContinue } = useContinueHook()

const phonePannel = ref(null)

const handleSendCodeCb = async (scene, smsType, from) => {
  const result = await phonePannel.value?.handleSendCode(scene, smsType, from)
  return result
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NewMainIndex',
})
</script>

.<style lang="less" scoped>
.page__login-newUI-continue {
  min-height: 100%;
  height: 100%;
  padding: 0 24px;
  position: relative;
  display: flex;
  flex-direction: column;
}
</style>
