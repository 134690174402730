<template>
  <div class="page__login-newUI-continueBody">
    <LoginHead
      :showback="true"
      type="continue"
      @back="handleContinueGoBack"
    /> 
    <ClientOnly>
      <RelatedAccountTip v-if="relatedAcc.global" />
    </ClientOnly>
    <NewUserRights
      v-if="!isInRelatedAcc"
      scene="combine"
      type="combine"
    />
    <div class="input-fileds fsp-element">
      <login-input
        ui-type="new"
        :model-value="newUIStates.alias" 
        :tips="aliasTip.txt"
        :label="aliasInputText"
        :mode="newUIStates.mode"
        @update:model-value="handleAliasChange"
        @blur="handleAliasBlur"
        @focus="handleAliasFocus"
      >
        <template #prev>
          <ClientOnly>
            <transition name="page__login_phone-trans">
              <div
                v-show="showAreadCode"
                class="page__login_inputArea-countryCode"
              >
                <span
                  class="country-area"
                  da-event-click="2-8-69"
                  @click="handleAreaClick"
                >{{ areaCode.abbr }} +{{ areaCode.code }} <i class="suiiconfont sui_icon_more_s_triangle_down_12px"></i></span>
              </div>
            </transition>  
          </ClientOnly>
        </template>
      </login-input>
    </div>
    <div class="actions">
      <!-- 手机登陆注册 支持 WhatsApp 交互 -->
      <template v-if="commonAbt.WhatsAppSetup.show == 'on' && isSupportPhone">
        <ClientOnly>
          <div
            v-if="newUIStates.alias != '' && newUIStates.mode == 'phone'"
            class="sub-actions"
          >
            <div
              v-if="newUIStates.alias != ''"
              :class="smsType.class"
            >
              <LoginPointButton
                class="main-button"
                :text="langText?.SHEIN_KEY_PWA_22291"
                :langText="langText"
                :configData="LoginButtonPoint"
                :isUsed="smsType.isUsed"
                :isContinue="true"
                :type="smsType.type"
                @click="handleContinue('sms')"
              />
            </div>
            <div
              v-if="newUIStates.alias != ''"
              :class="whtasAppType.class"
            >
              <LoginPointButton
                class="main-button"
                :text="langText?.SHEIN_KEY_PWA_22292"
                :langText="langText"
                :configData="LoginButtonPoint"
                :isWhtasApp="whtasAppType.isUsed"
                :isUsed="whtasAppType.isUsed"
                :isContinue="true"
                :isShowSubscript="false"
                :type="whtasAppType.type"
                @click="handleContinue('whatsApp')"
              />
            </div>
          </div>
        </ClientOnly>
        <div
          v-if="!newUIStates.alias || newUIStates.mode == 'email'"
          class="sub-actions fsp-element"
        >
          <LoginPointButton
            class="main-button"
            :text="langText?.SHEIN_KEY_PWA_20741"
            :langText="langText"
            :configData="LoginButtonPoint"
            :isContinue="true"
            :type="['primary', 'H88PX']"
            @click="handleContinue"
          />
        </div>
      </template>
      <div
        v-else
        class="sub-actions fsp-element"
      >
        <LoginPointButton
          class="main-button"
          :text="langText?.SHEIN_KEY_PWA_20741"
          :langText="langText"
          :configData="LoginButtonPoint"
          :isContinue="true"
          :type="['primary', 'H88PX']"
          @click="handleContinue"
        />
      </div>
    </div>
    <!-- 游客模式privacy入口 -->
    <ClientOnly>
      <PrivacyCenterMode
        v-if="loginPopConfig?.from == 'privacyRequest'"
        :lang-text="langText"
      />
      <ExtraInfo v-if="loginPopConfig?.toRobot" />
    </ClientOnly>
    <ThirdPartLogin
      class="fsp-element"
      @show-bind-email="showBindEmail"
    />
    <SwitchSite />
    <div
      v-if="publicLocalData.SiteUID != 'mkr'"
      class="page__login-newUI-continueFooter fsp-element"
    >
      <div
        class="privacy-tips"
        @click="handleNewPrivacyClick"
        v-html="combineNewPrivacyLanguage({ language: langText, from: 'newui-continue', location: 'combine' })"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@login/hooks/utils.js'
import LoginPointButton from '@login/components/LoginPointButton/index.vue'
import LoginHead from './components/head.vue'
import SwitchSite from '@login/components/SwitchSite/index.vue'
import NewUserRights from '@login/components/NewUserRights/index.vue'
import ThirdPartLogin from './components/third_part_login.vue'
import PrivacyCenterMode from '@login/components/PrivacyCenterMode/index.vue'
import { ExtraInfo } from '@login/components/index'
import RelatedAccountTip from '@login/components/RelatedAccount/RelatedAccountTip.vue'
import { checkEmail, combineNewPrivacyLanguage, handleNewPrivacyClick } from '@login/util'
import { checkRelationAccSer } from '@login/service'
import { Metric_Login_Continue_Click } from '@login/utils/monitorLog.js'
import { saSend } from '@login/analysis/util'
import { urlRedirection, sleep } from '@login/util'
import useContinueHook from './hooks/useContinueHook.js'
import useLocationHook from './hooks/useLocationHook.js'
import useCommonHandle from '@login/hooks/useCommonHandle.js'
import useRetainModalHook from '@login/hooks/useRetainModalHook.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import LoginInput from  '@login/libs/LoginInput/index.vue'
const props = defineProps({
  codeResult: {
    type: Function,
    default: () => {},
  }
})

const store = useStore()

const { langText, newUIStates, preloadData, relatedAcc, commonAbt, publicLocalData, accountList, isInRelatedAcc, LoginButtonPoint, isAsComponent, areaCode, loginPopConfig, showAreadCode, showPointRetainModal, filterAreaCodeTip, isSupportPhone, aliasInputText, whtasAppType, smsType } = useContinueHook()

const { aliasTip, showInputTips,  handleFilterAreaCode } = useCommonHandle()

const { handleUserLocation, handlePhoneBlurCheck } = useLocationHook({ aliasTip, areaCode, langText, newUIStates, preloadData, commonAbt, publicLocalData, accountList, relatedAcc, filterAreaCodeTip, handleFilterAreaCode, showInputTips, handleSendCode: props.codeResult })

const { handleCloseRetainModal } = useRetainModalHook()


const handleContinueGoBack = () => {
  if(newUIStates.value?.isToLogin){
    store.commit('login/setnewUIStates', { showType: 'continue', isToLogin: false, isSwitch: false, mode: '', alias: '', type: '', sendType: '', subscribe_status: '', locationRiskId: '' })
    store.commit('login/assignState', { showAreadCode: false })
    return
  }
  handleCloseRetainModal()
  sleep(0)
  if (showPointRetainModal.value == true) {
    return
  }
  store.commit('login/setShowIndex', false)
  store.commit('login/assignState', { showAreadCode: false })
  urlRedirection(true, {}, isAsComponent.value)
  SHEIN_LOGIN.instanceActions.pop()
}

const handleAliasChange = (val = '') => {
  const alias = val.trim()
  showInputTips(aliasTip.value, '', false)
  store.commit('login/setnewUIStates', { alias })
  if(!isSupportPhone.value){
    store.commit('login/setnewUIStates', { mode: 'email' })
    return
  }
  if(alias == ''){
    store.commit('login/assignState', { showAreadCode: false })
    store.commit('login/setnewUIStates', { mode: '' })
    return
  }
  if(/^\d+$/.test(alias)){
    store.commit('login/assignState', { showAreadCode: true })
    store.commit('login/setnewUIStates', { mode: 'phone' })
  } else {
    store.commit('login/assignState', { showAreadCode: false })
    store.commit('login/setnewUIStates', { mode: 'email' })
  }
}

const handleAliasBlur = () => {
  const { alias, mode } = newUIStates.value
  if (!alias) return
  if (mode == 'email' && !checkEmail(alias, aliasTip.value)) {
    return
  }
  if(relatedAcc.value?.global)return
  if(mode == 'phone'){
    handlePhoneBlurCheck()
  }
}

const handleAliasFocus = () => {
  store.commit('login/assignState', { loadComponent: true })
}

const handleAreaClick = () => {
  store.commit('login/setAreaCodePop', { val: true, cb: () => handlePhoneBlurCheck?.() })
}

const handleContinue = async (smsType = 'sms') => {
  const { alias, mode } = newUIStates.value
  if(!alias) return
  handleAliasChange(alias)
  if(mode == 'email' && !checkEmail(alias, aliasTip.value)){
    saSend('2-8-100', { result_reason: -1, code: -1, scene: '-', type: mode })
    Metric_Login_Continue_Click({ account_type: mode, result: 'failed', error: '-', sub_site: publicLocalData.value?.SiteUID || '' })
    return
  }
  const { global, abt } = relatedAcc.value
  if(global && abt == 'related'){
    const { pass, tips } = await checkRelationAcc()
    if(!pass){
      showInputTips(aliasTip.value, tips)
      return
    }
  }
  handleUserLocation(smsType)
}

const checkRelationAcc = async () => {
  const { alias } = newUIStates.value
  const { code: area_code } = areaCode.value
  const { type, from } = relatedAcc.value

  const data = {
    alias,
    area_code: type == 'phone' ? area_code : '',
    alias_type: type == 'phone' ? 2 : 1,
    scene: from == 'order_list' ? 'order_list' : 'login_register',
    relation_token: relatedAcc.value?.selected?.relation_token,
  }
  const { code, tips, info } = await checkRelationAccSer(data)
  if(code == 0 && info?.result == 1){
    return { pass: true }
  }
  if(code == 0 && info?.result == 0){
    return { pass: false, tips: type == 'email' ? langText.value?.SHEIN_KEY_PWA_21281 : langText.value?.SHEIN_KEY_PWA_21303 }
  }
  return { pass: false, tips: tips || langText.value?.SHEIN_KEY_PWA_14899 }
}

const showBindEmail = (data = {}) => {
  const newVal = { show: true, ...data }
  newVal.originType = data.type
  store.commit('login/changeDialogs', { key: 'bindModalInfo', val: newVal })
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ContinuePage',
})
</script>

<style lang="less" scoped>
.page__login-newUI-continueBody {
    flex: 1 1 100%;
  .actions{
    width: 100%;
    margin-top: 24px;
    button {
      width: 100%;
    }
  }
  .sub-actions{
    display: flex;
    flex-direction: column;
  }
  .orderIndex {
    margin-bottom: 12px;
    order: 1;
    position: relative;
  }
  .orderSecond {
    order: 2;
    position: relative;
  }
  .privacy-tips {
    text-align: center;
    padding: 12px 0;
    font-size: 12px;
    color: @sui_color_gray_light1;
    :deep(a) {
      color: @sui_color_link;
      text-decoration: none;
    }
  }
  .login-privacy {
    /* stylelint-disable-next-line */
    padding: 0 !important;
  }
  :deep(.page__login-inputComp_wrapper){
    label{
      color: @sui_color_gray_dark3;
    }
  }
}
</style>
