<template>
  <div class="filterAreaCodeTips-container">
    <s-alert
      type="info"
      jumpable
      @click.stop="handleShowModal"
    >
      {{ langText.SHEIN_KEY_PWA_28528 }}
    </s-alert>
  </div>
</template>

<script setup>
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'

const store = useStore()

const langText = computed(() => store.state.login.langText)

const handleShowModal = () => {
  store.commit('login/assignState', { isShowFilterAreaCodeModal: true })
// eslint-disable-next-line semi
};

</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FilterAreaCodeTips'
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-max-specificity */
 .filterAreaCodeTips-container{
   :deep(.sui-alert__flex.sui-alert){
  height: 66px;
 }
 :deep(.sui-alert__description){
  line-height: 0.382rem;
  color: @sui_color_gray_dark3;
 }
  :deep(.ivULGa.sui-alert__flex .sui-alert__flex-closebtn){
    transform: rotate(180deg);
  }
  :deep(.ejXyOx.sui-alert__flex .sui-alert__flex-closebtn){
    transform: rotate(180deg);
  }
 }
 
</style>
