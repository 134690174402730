<template>
  <svg
    width="285"
    height="102"
    viewBox="0 0 285 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="285"
      height="100"
      fill="url(#paint0_linear_1378_9566)"
    />
    <g
      style="mix-blend-mode:soft-light"
      opacity="0.4"
    >
      <mask
        id="mask0_1378_9566"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="285"
        height="100"
      >
        <path
          d="M285 0H0V100H285V0Z"
          fill="url(#paint1_linear_1378_9566)"
        />
      </mask>
      <g mask="url(#mask0_1378_9566)">
        <g
          style="mix-blend-mode:soft-light"
          opacity="0.35"
        >
          <path
            d="M142.5 48L0.0400391 -48.62V-82.22H33.64L142.5 48Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 48L83.8101 -82.22H117.42L142.5 48Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 48L167.59 -82.22H201.19L142.5 48Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 -48.62L142.5 48L251.36 -82.22H284.96V-48.62Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 48L0.0400391 26.99V-6.60999L142.5 48Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 26.99L142.5 48L284.96 -6.60999V26.99Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 48L0.0400391 102.6V69L142.5 48Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 102.6L142.5 48L284.96 69V102.6Z"
            fill="#F5FCFB"
          />
          <path
            d="M33.64 178.21H0.0400391V144.61L142.5 48L33.64 178.21Z"
            fill="#F5FCFB"
          />
          <path
            d="M117.42 178.21H83.8101L142.5 48L117.42 178.21Z"
            fill="#F5FCFB"
          />
          <path
            d="M201.19 178.21H167.59L142.5 48L201.19 178.21Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 178.21H251.36L142.5 48L284.96 144.61V178.21Z"
            fill="#F5FCFB"
          />
        </g>
      </g>
    </g>
    <g filter="url(#filter0_d_1378_9566)">
      <mask
        id="path-15-inside-1_1378_9566"
        fill="white"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M62 22.5C62 21.9477 62.4477 21.5 63 21.5H221C221.552 21.5 222 21.9477 222 22.5V57.752C218.55 58.6401 216 61.7723 216 65.5C216 69.2277 218.55 72.3599 222 73.248V98.5C222 99.0523 221.552 99.5 221 99.5H63C62.4477 99.5 62 99.0523 62 98.5V73.248C65.4505 72.3599 68 69.2277 68 65.5C68 61.7723 65.4505 58.6401 62 57.752V22.5Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62 22.5C62 21.9477 62.4477 21.5 63 21.5H221C221.552 21.5 222 21.9477 222 22.5V57.752C218.55 58.6401 216 61.7723 216 65.5C216 69.2277 218.55 72.3599 222 73.248V98.5C222 99.0523 221.552 99.5 221 99.5H63C62.4477 99.5 62 99.0523 62 98.5V73.248C65.4505 72.3599 68 69.2277 68 65.5C68 61.7723 65.4505 58.6401 62 57.752V22.5Z"
        fill="url(#paint2_linear_1378_9566)"
      />
      <path
        d="M222 57.752L222.125 58.2363L222.5 58.1396V57.752H222ZM222 73.248H222.5V72.8604L222.125 72.7637L222 73.248ZM62 73.248L61.8754 72.7637L61.5 72.8604V73.248H62ZM62 57.752H61.5V58.1396L61.8754 58.2363L62 57.752ZM63 21C62.1716 21 61.5 21.6716 61.5 22.5H62.5C62.5 22.2239 62.7239 22 63 22V21ZM221 21H63V22H221V21ZM222.5 22.5C222.5 21.6716 221.828 21 221 21V22C221.276 22 221.5 22.2239 221.5 22.5H222.5ZM222.5 57.752V22.5H221.5V57.752H222.5ZM216.5 65.5C216.5 62.006 218.89 59.0689 222.125 58.2363L221.875 57.2678C218.209 58.2114 215.5 61.5387 215.5 65.5H216.5ZM222.125 72.7637C218.89 71.9311 216.5 68.994 216.5 65.5H215.5C215.5 69.4613 218.209 72.7886 221.875 73.7322L222.125 72.7637ZM222.5 98.5V73.248H221.5V98.5H222.5ZM221 100C221.828 100 222.5 99.3284 222.5 98.5H221.5C221.5 98.7761 221.276 99 221 99V100ZM63 100H221V99H63V100ZM61.5 98.5C61.5 99.3284 62.1716 100 63 100V99C62.7239 99 62.5 98.7761 62.5 98.5H61.5ZM61.5 73.248V98.5H62.5V73.248H61.5ZM67.5 65.5C67.5 68.994 65.1103 71.9311 61.8754 72.7637L62.1246 73.7322C65.7906 72.7886 68.5 69.4613 68.5 65.5H67.5ZM61.8754 58.2363C65.1103 59.0689 67.5 62.006 67.5 65.5H68.5C68.5 61.5387 65.7906 58.2114 62.1246 57.2678L61.8754 58.2363ZM61.5 22.5V57.752H62.5V22.5H61.5Z"
        fill="#C23C16"
        fill-opacity="0.16"
        mask="url(#path-15-inside-1_1378_9566)"
      />
      <line
        x1="82"
        y1="66"
        x2="202"
        y2="66"
        stroke="#FF6F46"
        stroke-opacity="0.3"
        stroke-dasharray="4 4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1378_9566"
        x="58"
        y="15.5"
        width="168"
        height="86"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood
          flood-opacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.85 0 0 0 0 0.665304 0 0 0 0 0.612708 0 0 0 0.17 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1378_9566"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1378_9566"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1378_9566"
        x1="142.5"
        y1="-4.60811e-08"
        x2="141.957"
        y2="99.9998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FC6D44" />
        <stop
          offset="1"
          stop-color="#FF6F46"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1378_9566"
        x1="142.5"
        y1="142.469"
        x2="142.5"
        y2="0.656249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FCFB" />
        <stop
          offset="0.22"
          stop-color="#83BCA5"
        />
        <stop
          offset="1"
          stop-color="#198055"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1378_9566"
        x1="142"
        y1="21.5"
        x2="142"
        y2="99.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFF6F3" />
        <stop
          offset="1"
          stop-color="#FFE6DE"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LeafletCoupon'
})
</script>
