<template>
  <s-drawer
    v-model:visible="smsSubScribeModal.show"
    :append-to-body="true"
    :immediately-render="true"
    :show-close="true"
    :direction="'btt'"
    :close-on-click-modal="false"
    class="sms__subScribe_container"
    custom-class="sms__subScribe_pop"
    @close-from-icon="handleCloseSubScribe"
  > 
    <template #title>
      <i></i>
    </template>
    <div>
      <h1>{{ langText.SHEIN_KEY_PWA_30942 }}</h1>
      <p 
        @click="handleNewPrivacyClick"
        v-html="smsSubScribePrivacyText({from: 'phone-login', language: langText, alias: phoneAlias })"
      ></p>
    </div>
    <div class="sms__subScribe_btn">
      <SButtonGroup
        width="100%"
        hor
      >
        <SButtonGroupItem
          @click="handleRefuseSubScribe"
        >
          {{ langText.SHEIN_KEY_PWA_30943 }}
        </SButtonGroupItem>
        <SButtonGroupItem
          :type="['primary']"
          @click="handleAgreeSubScribe"
        >
          {{ langText.SHEIN_KEY_PWA_30944 }}
        </SButtonGroupItem>
      </SButtonGroup>
    </div>
  </s-drawer>
</template>

<script setup>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { computed, onMounted } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import { refuseSubScribe, agreeSubScribe } from '@login/services/subScribe.js'
import { handleNewPrivacyClick } from '@login/util'
import { smsSubScribePrivacyText } from '@login/utils/subScribe.js'
import { exposeLoginSmsPopup, clickLoginSmsPopup } from '@login/utils/loginAnalysis.js'

const store = useStore()

const langText = computed(() => store.state.login.langText )
const smsSubScribeModal = computed(() => store.state.login?.dialogs?.smsSubScribeModal)
const phoneAlias = computed(() => {
  const { alias, desensitize_alias, code: area_code  } = store.state.login.newUIStates
  const code = store.state.login.areaCode.code
  return desensitize_alias ? `+${area_code} ${desensitize_alias}` : `+${code} ${alias}`
} )


const handleRefuseSubScribe = async () => {
  clickLoginSmsPopup('refuse')
  handleCommonFun()
  refuseSubScribe()
}

const handleAgreeSubScribe = async () => {
  clickLoginSmsPopup('agree')
  handleCommonFun()
  agreeSubScribe()
}

const handleCloseSubScribe = () => {
  clickLoginSmsPopup('close')
  handleCommonFun()
}

const handleCommonFun = () => {
  const { cb } = smsSubScribeModal.value
  store.commit('login/changeDialogs', { key: 'smsSubScribeModal', val: { show: false } })
  cb && cb()
}

onMounted(() => {
  exposeLoginSmsPopup()
})
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SmsSubScribe'
})
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern */
.sms__subScribe_container{
  h1{
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    line-height: normal;
  }
  p{
    margin-top: 8px;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    color: @sui_color_gray_dark1;
  }
  .sms__subScribe_btn{
    padding: 8px 12px;
    margin-bottom: 20px;
  }
  :deep(.j-privacy-and-cookies),
  :deep(.j-terms-and-conditions) {
    text-decoration: none;
    color: @sui_color_link;
  }
}
</style>

<style lang="less">
.sui-drawer {
  .sms__subScribe_pop {
    .sui-drawer__header {
      border-bottom: unset;
    }
    .sui-drawer__header_normal{
      min-height: 0.5rem;
    }
  }
}
</style>
