<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15"
      r="12.5"
      stroke="#198055"
      stroke-width="3"
    />
    <path
      d="M21.7882 10.4745L12.7373 19.5255L8.21179 15"
      stroke="#198055"
      stroke-width="3"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SuccessIcon'
})
</script>
