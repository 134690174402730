<template>
  <div class="freeShipping-container">
    <FreeShippingBg />
  </div>
</template>

<script setup>
import FreeShippingBg from './icon/FreeShippingBg.vue'
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FreeShipping'
})
</script>

<style lang="less" scoped>
.freeShipping-container{
  position: relative;
  top: -1px;
}
</style>
