<template>
  <s-drawer
    :visible="relatedAcc.show"
    :type="'multi'"
    class="page__login_relatived-accont"
    :class="{ 'page__login_relatived-accontOrder': relatedAcc.from == 'order_list' }"
    :append-to-body="true"
    @close-from-icon="handleClose"
  >
    <h4>{{ title }}</h4>
    <p class="sub-title">
      {{ subTitle }}
    </p>
    <div
      v-if="relatedAcc.abt == 'related'"
      class="related-list"
    >
      <ul
        v-for="item in relatedAcc.list"
        :key="item.member_id"
        :label="item.member_id"
      > 
        <li
          class="list-box"
          @click="handleItemChange(item)"
        >
          <p 
            class="list-item"
          >
            <span
              v-if="publicLocalData.WEB_CLIENT == 'romwe' && item.register_from == 11"
              class="icon-third iconfont icon-facebook rw-fb-login"
            ></span>
            <span
              v-if="publicLocalData.WEB_CLIENT != 'romwe' && item.register_from == 11"
              class="icon-third iconfont icon-Facebook"
            ></span>
            <span
              v-if="item.register_from == 12"
              class="icon-third iconfont icon-Google1"
            >
              <GoogleIcon />
            </span>
            <span
              v-if="item.register_from == 13"
              class="icon-third iconfont icon-vk1"
            ></span>
            <span
              v-if="item.register_from == 15"
              class="icon-third line-login"
            >
              <LineIcon />
            </span>
            <span
              v-if="item.register_from == 16"
              class="icon-third kakao-login"
            >
              <KakaoIcon />
            </span>
            <span
              v-if="item.register_from == 17"
              class="icon-third naver-login"
            >
              <NaverIcon />
            </span>
            <span>{{ item.alias_type == '1' ? item.alias : `+${item.area_code} ${item.alias}` }}</span>
          </p>
          <span class="suiiconfont sui_icon_more_right2_16px"></span>
        </li>
      </ul>
    </div>
    <div
      v-else
      class="free-list"
    >
      <s-button
        class="switch-btn"
        :type="['default']"
        @click="handleFreeButonClick"
      >
        {{ freeButtonText }}
      </s-button>
    </div>
    <template #footer>
      <p
        v-if="relatedAcc.from != 'order_list'"
        class="sub-tips"
        @click="hanleContinueRegister"
      >
        {{ tipsText + '>' }}
      </p>
    </template>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { defineComponent } from 'vue'
import { mapMutations, mapState } from 'vuex'
import GoogleIcon from '../Icon/GoogleIcon.vue'
import LineIcon from '../Icon/LineIcon'
import KakaoIcon from '../Icon/KakaoIcon.vue'
import NaverIcon from '../Icon/NaverIcon'
import { getSessionStorage } from '@shein/common-function'
import analysisIndex from '../../analysis/index'
import thirdMixin from 'public/src/pages/login/mixin/third_part_login.js'
const { sensorsSend } = analysisIndex

export default defineComponent({
  name: 'RelatedAccount',
  components: { GoogleIcon, LineIcon, KakaoIcon, NaverIcon, SButton, SDrawer },
  mixins: [ thirdMixin ],
  data: () => ({
    selected: '',
  }),
  computed: {
    ...mapState('login', ['relatedAcc', 'langText', 'newPrivacyAbt', 'commonAbt', 'publicLocalData']),
    title () {
      if (this.relatedAcc.from == 'order_list') { // 来自订单列表关联分支
        return this.langText.SHEIN_KEY_PWA_21311
      }
      if (this.relatedAcc.abt == 'free') { // 自由分支
        return this.langText.SHEIN_KEY_PWA_21284
      }
      return this.langText.SHEIN_KEY_PWA_21309
    },
    subTitle () {
      if (this.relatedAcc.from == 'order_list') { // 来自订单列表关联分支
        if (this.relatedAcc.abt == 'free') {
          return this.langText.SHEIN_KEY_PWA_21299
        }
        return this.langText.SHEIN_KEY_PWA_21298
      }

      const abt = this.relatedAcc.abt 
      if (this.relatedAcc.from == 'phone_login_related_account') { // 来自手机登陆的管理账号
        return abt == 'free' ? this.langText.SHEIN_KEY_PWA_21295.split : this.langText.SHEIN_KEY_PWA_21294
      }

      return abt == 'free' ? this.langText.SHEIN_KEY_PWA_21285 : this.langText.SHEIN_KEY_PWA_21277

    },
    tipsText () {
      return this.relatedAcc.abt == 'free' ? this.langText.SHEIN_KEY_PWA_24334 : this.langText.SHEIN_KEY_PWA_24330
    },
    freeButtonText () {
      if (this.relatedAcc.from == 'order_list') return this.langText.SHEIN_KEY_PWA_21312
      return this.langText.SHEIN_KEY_PWA_21286
    }
  },
  mounted() {
    setTimeout(()=> {
      import('public/src/pages/login/common/index.js').then(({ initThirdLoginLibs }) => {
        initThirdLoginLibs()
      })
    }, 200)
  },
  methods: {
    ...mapMutations('login', ['setRelatedAcc', 'setShowIndex', 'setNewPrivacyModal', 'assignState']),
    handleItemChange (item) {
      const selected = { ...item }
      const page = selected.alias_type == '1' ? 'email' : 'phone'
            
      // 选择的账号是否是3方账号类型
      const isThirdPartType = [11, 12, 13, 14, 15, 16, 17].includes(selected.register_from)
      this.setRelatedAcc({ selected, global: true, is_relation: 1, type: page, isThird: isThirdPartType })
      sensorsSend('2-8-80', { relate_type: 'related', type: page })
      if (isThirdPartType) {
        this.handleRelationButton(selected.register_from)
        return
      }
      this.setShowIndex(true)
      // this.changePageShow({ index: 0, page })
            
    },
    hanleContinueRegister () {
      const isAgree = getSessionStorage('checkboxPrivacy') || ''
      if(isAgree != true) {
        const { origin = {} } = this.relatedAcc
        const { alias, alias_type, clause_agree } = origin
        if (this.newPrivacyAbt.type == 'yes' && clause_agree != 1) {
          this.setNewPrivacyModal({
            from: alias_type == '1' ? 'Email' : 'PHone',
            account: alias,
            cb: () => {
              this.setRelatedAcc({ show: false })
              this.relatedAcc.cb?.({ type: 'force' })
            }
          })
          return
        }
      }
      this.setRelatedAcc({ show: false })
      this.relatedAcc.cb?.({ type: 'force' })
    },
    handleFreeButonClick () {
      const page = this.relatedAcc.list?.[0]?.alias_type  == '1' ? 'email' : 'phone'
      // this.changePageShow({ index: 0, page })
      this.setShowIndex(true)
      this.setRelatedAcc({ global: true, is_relation: 1, type: page })
      sensorsSend('2-8-83')
    },
    handleClose () {
      if(this.commonAbt.RelatedAccountRetention == 'on' && this.relatedAcc.isRetain == false){
        this.$emit('close')
        this.relatedAcc.cb?.({ type: 'retain' }) 
        return
      } 
      this.$emit('close')
      this.relatedAcc.cb?.({ type: 'close' })    
      sensorsSend('2-8-82', { related_type: this.relatedAcc.abt })
    }
  }
})
</script>

<style lang="less">
.page__login_relatived-accont {
    .sui-drawer__body {
        padding: 0 24px;
    }
    .sui-drawer__close-btn{
      font-size: 16px;
    }
    h4 {
        .font-dpr(32px);
        padding: 4px 0;
        text-align: center;
    }
    .sub-title {
        .font-dpr(28px);
        color: #767676;
        line-height: 1.2;
        padding: 8px 0;
        .txt-l();
    }
    .switch-btn {
        margin: 8px auto;
        display: block;
    }
    .sub-tips {
        padding: 16px 24px 24px 24px;
        text-align: center;
        color: @sui_color_gray_light1;
        cursor: pointer;
        line-height: 1.1;
    }
    &.page__login_relatived-accontOrder {
        .sui-drawer__body {
            padding: 0 24px 20px 24px;
        }
    }
    .list-box{
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      padding: 8px 12px;
      margin-top: 8px;
      font-size: 14px;
      color: @sui_color_gray_dark1;
      border: 1px solid @sui_color_gray_weak1;
    }
    .list-item{
      flex: 1;
      display: flex;
      align-items: center;
    }
    .icon-third {
        width: 20px;
        height: 20px;
        flex: 0 0 16px;
        font-size: 20px;
        .margin-r(10px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon-Google1, .rw-fb-login {
      border: 1px solid #E5E5E5;
      padding: 1px;
      border-radius: 50%;
    }
    .rw-fb-login {
      color: #4a90e2; 
    }
    .icon-Facebook {
        color: #3949ab;
    }
    .icon-vk1 {
        color: #4680c2;
    }
}
</style>
