<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 115 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      width="114"
      height="48"
      rx="4"
      fill="#F6F6F6"
    />
    <rect
      x="0.5"
      width="114"
      height="48"
      rx="4"
      fill="url(#paint0_linear_561_14686)"
    />
    <rect
      x="76.5"
      y="12"
      width="26"
      height="26"
      fill="white"
      fill-opacity="0.01"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M77.5 13.7334C76.9477 13.7334 76.5 14.1811 76.5 14.7334V19.223C76.5 19.7753 76.9477 20.223 77.5 20.223H78.3574V35.2666C78.3574 35.8189 78.8051 36.2666 79.3574 36.2666H99.6431C100.195 36.2666 100.643 35.8189 100.643 35.2666V20.223H101.5C102.052 20.223 102.5 19.7753 102.5 19.223V14.7334C102.5 14.1811 102.052 13.7334 101.5 13.7334H77.5Z"
      fill="url(#paint1_linear_561_14686)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M84.147 26.3484L83.2251 27.1166L84.147 27.8848L88.3373 31.3768L89.6177 29.8403L87.5489 28.1164H92.8663V29.9099H94.8663V27.1164V26.1164H93.8663H87.5495L89.6177 24.3929L88.3373 22.8564L84.147 26.3484Z"
      fill="url(#paint2_linear_561_14686)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_561_14686"
        x1="-52.508"
        y1="23.1596"
        x2="-20.1811"
        y2="102.722"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0.5"
        />
        <stop
          offset="1"
          stop-color="#F6F6F6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_561_14686"
        x1="102.45"
        y1="27.0097"
        x2="75.1434"
        y2="12.5389"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#959595" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.83"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_561_14686"
        x1="95"
        y1="27"
        x2="80.5"
        y2="27.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.34"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ReturnIcon'
})
</script>
