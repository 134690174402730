<template>
  <div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ThirdPartLogin from '@login/mixin/third_part_login.js'
export default defineComponent({
  name: 'ThirdLoginFunction',
  mixins: [ThirdPartLogin]
})
</script>
