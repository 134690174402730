<template>
  <s-drawer
    :visible="newUIStates.showType == 'encryptionEmail'"
    type="full"
    direction="rtl"
    :no-header="true"
    :append-to-body="true"
    :immediately-render="true"
  >
    <EmailLoginPage />
  </s-drawer>
</template>

<script setup>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { computed } from 'vue'
import { useStore } from '@login/hooks/utils.js'
import EmailLoginPage from '@login/components/MultiAccount/EmailLoginPage.vue'

const store = useStore()

const newUIStates = computed(() => store.state.login.newUIStates)

;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'EncryptionEmail',
})
</script>
