<template>
  <s-dialog
    class="validate-email-modal"
    :close-on-click-modal="false"
    :append-to-body="true"
    :show-close="true"
    :visible="isShow"
    @close="handleClose"
  >
    <div class="head">
      {{ langText.SHEIN_KEY_PWA_18016 }}
    </div>
    <div class="field focus">
      <label for="">
        {{ langText.SHEIN_KEY_PWA_15288 }}
      </label>
      <input
        type="text"
        :disabled="true"
        :readonly="true"
        :value="riskInfo.aliasMark"
      />
    </div>
    <div
      class="field"
      :class="{ 'is-error': codeTip.show, focus: isFocus || Boolean(String(code)) }"
      @click="handleFocus"
    >
      <div class="input-box">
        <label for="">
          {{ langText.SHEIN_KEY_PWA_17724 }}
        </label>
        <input
          ref="codeInput"
          v-model="code"
          inputmode="numeric"
          maxlength="5"
          @blur="isFocus = false"
        />
        <s-button
          v-show="codeDownTime <= 0"
          class="send-btn"
          :type="['H48PX']"
          @click="handleSendCode()"
        >
          {{ codeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
        </s-button>
        <s-button
          v-show="codeDownTime > 0"
          class="send-btn"
          :type="['H48PX']"
        >
          {{ codeTimeText }}
        </s-button>
      </div>
      <p class="error-tip">
        {{ codeTip.txt }}
      </p>
    </div>
    <s-button
      class="submit"
      :type="['primary', 'H72PX']"
      width="100%"
      :disabled="!isFinish"
      @click="handleSubmit"
    >
      {{ langText.SHEIN_KEY_PWA_16462 }}
    </s-button>
    <p
      class="warm-tip"
      @click="onlineHelp"
      v-html="warmTip"
    ></p>
    <template #footer>
      <div></div>
    </template>
    <!--loading-->
    <s-loading
      :show="showLoading"
      type="curpage"
      :append-to-body="true"
    />
  </s-dialog>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { nextTick, defineComponent } from 'vue'
import { riskCodeVerifySer, riskSendCodeSer } from '@login/service'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { saSend } from '@login/analysis/util'
daEventCenter.addSubscriber({ modulecode: '2-16' })

const { IS_RW } = gbCommonInfo

export default defineComponent({
  name: 'SEmailValidate',
  components: {
    SButton,
    SLoading,
    SDialog,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    riskInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    langText: { 
      type: Object,
      default: () => ({})
    }, 
  },
  data() {
    return {
      code: '',
      codeTip: { show: false, txt: '' },
      codeDownTime: 0,
      codeTimeText: '',
      codeIsSended: false, // 是否已经发送过验证
      isCodeSending: false,
      isFocus: false,
      isFinish: false,
      showLoading: false,
      isShowModal: false
    }
  },
  computed: {
    btnTxtMap() {
      return {
        send: this.langText.SHEIN_KEY_PWA_15323,
        resend: this.langText.SHEIN_KEY_PWA_16372,
      }
    },
    warmTip() {
      if (IS_RW) {
        return (this.langText.SHEIN_KEY_PWA_17754 || '').replace('#1860A7', '#5A29BB')
      }
      return this.langText.SHEIN_KEY_PWA_17754?.replace('#1860A7', '#2D68A8')
    }
  },
  watch: {
    code(val) {
      this.isFinish = /^\d{5}$/.test(val)
    },
    codeDownTime(time) {
      const [mins, seconds] = [
        Math.floor(time / 60),
        time % 60,
      ]
      const n = `${String(mins).padStart(2, '0')}:${String(
        seconds
      ).padStart(2, '0')}`
      this.codeTimeText = n
    },
    isShow: {
      handler(v){
        if(v){
          this.sendCodeRequest(this.riskInfo, true)
          daEventCenter.triggerNotice({ daId: '2-16-1', extraData: { scene: 'login', login_from: this.riskInfo.login_from } })
        }
      }, immediate: true
    }
  },
  beforeUnmount() {
    daEventCenter.triggerNotice({ daId: '2-16-2' })
  },
  methods: {
    getVerifyTipByCode(code) {
      if (code == 9002) {
        return this.langText.SHEIN_KEY_PWA_17720
      }
      if (code == 9003) {
        return this.langText.SHEIN_KEY_PWA_17634
      }
      if (code == 9005) {
        return this.langText.SHEIN_KEY_PWA_17719
      }
      if (code == 9007) {
        return this.langText.SHEIN_KEY_PWA_17712
      }
      return this.langText.SHEIN_KEY_PWA_17634
    },
    handleFocus() {
      this.isFocus = true
      nextTick(() => {
        this.$refs.codeInput.focus()
      })
    },
    showAliasErrorTips(msg, show = true) {
      this.codeTip.show = show
      this.codeTip.txt = msg
    },
    handleClose() {  
      this.$emit('close')
      this.codeDownTime = 0
      this.code = ''
      this.showAliasErrorTips('', false)
    },
    handleSendCode() {
      if(this.codeDownTime > 0) return
      if (!this.isCodeSending) {
        this.showAliasErrorTips('', false)
        this.sendCodeRequest(this.riskInfo)
      }
    },
    async handleSubmit() {
      if (!this.isFinish) {
        return
      }
      this.showLoading = true
      this.showAliasErrorTips('', false)

      const params = {
        risk_id: this.riskInfo.risk_id, // 风控单ID
        content: this.code,
        unlogin: true,
      }
      const res = await riskCodeVerifySer(params).catch(() => {
        this.showLoading = false
      })
      
      this.showLoading = false
      if (res.code == 0) {
        this.handleClose()
        this.riskInfo.cb && this.riskInfo.cb()
      } else {
        this.showAliasErrorTips(this.getVerifyTipByCode(res.code) || res?.tips)
      }
      saSend('2-8-111', { code: res.code, scene: 'login' })
    },
    async onlineHelp() {
      const { robotLinkCommon } = await import('public/src/pages/common/utils/index.js')
      location.href = robotLinkCommon()
    },
    startDownTime(time) {
      this.codeDownTime = time
      let downTimer = null
      if (downTimer) clearTimeout(downTimer)
      if(time <= 0) return
      downTimer = setTimeout(() => {
        this.startDownTime(time - 1)
      }, 1000)
    },
    async sendCodeRequest({ risk_id, aliasMark: target, encrypt_email }, isAutoSend = false) {
      const from = Number(isAutoSend)
      this.isCodeSending = true

      const params = {
        scene: 'login', // 查看地址：view_shipping_addr 登录后： login_success 修改密码： change_pwd 钱包下单： choose_wallet 查看用户隐私：view_privacy 查看钱包余额：view_wallet_balance
        risk_id, // 风控单ID
        channel: 'mmp_email',
        target_key: undefined,
        target,
        encrypt_email,
        message_type: 'login_confirm',
        unlogin: true,
      }
      const res = await riskSendCodeSer(params)
      const { countdown_second } = res.info || ''
      this.isCodeSending = false
      if (res.code == 0 && res.info.sended === 1) {
        this.startDownTime(120)
        this.codeIsSended = true
        SToast(this.langText.SHEIN_KEY_PWA_17775)
      } else if (res.code == 0 && res.info.sended === -1) {
        this.showAliasErrorTips(this.langText.SHEIN_KEY_PWA_17712)
        this.startDownTime(countdown_second)
        this.codeIsSended = true
      } else {
        this.showAliasErrorTips(res.tips)
        this.isCodeSending = false
      }
      daEventCenter.triggerNotice({
        daId: '2-16-3',
        extraData: {
          result: Number(!(res.code == 0 && res.info.sended === 1)),
          from
        },
      })
    },
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.validate-email-modal {
  .modal-content {
    width: 7.6rem;
  }
  .c-modal .modal-body .icon-close {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    margin: 0;
    .font-dpr(20px);
    line-height: 1;
  }
  .head {
    margin-top: 0.24rem;
    margin-bottom: 0.1334rem;
    font-weight: bold;
    line-height: 0.50667rem;
    text-align: center;
    color: #333;
    .font-dpr(32px);
  }
  .field {
    & + .field {
      margin-top: 0.32rem;
    }
    padding: 0.2933rem 0 0;
    .txt-l();
    .input-box {
      position: relative;
      // padding-right: 1.3334rem /*rtl:ignore*/;
      .padding-r(1.3334rem);
      width: 100%;
      margin: 0;
    }
    input {
      display: none;
      width: 100%;
      margin-top: 0.0534rem;
      .font-dpr(28px);
      color: #222;
      border: 0;
    }

    .send-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 0.5334rem;
      line-height: 0.5334rem;
      min-width: 1.3067rem;
      padding: 0 0.2134rem;
      text-align: center;
      text-transform: capitalize;
    }
    label {
      .font-dpr(28px);
      color: #959595;
      line-height: 0.4534rem;
    }
    &.focus {
      label {
        color: #767676;
        .font-dpr(24px);
        line-height: 0.4rem;
      }
      input {
        display: block;
      }
    }
    &.is-error .error-tip {
      padding-top: 0.1067rem;
      border-bottom-color: #fe3b30;
    }
  }
  .error-tip {
    color: @sui_color_unusual;
    .font-dpr(24px);
    line-height: 0.44rem;
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 0.2534rem;
  }
  .sui-button-common.submit {
    margin-top: 0.5334rem;
  }
  .warm-tip {
    margin-top: 0.32rem;
    line-height: 0.3867rem;
    text-align: center;
  }
}

</style>
