<template>
  <div
    v-if="relatedAcc.global"
    class="page__login_related-accTips"
  >
    <div
      v-if="relatedAcc.abt == 'related'"
      class="related"
    >
      <p>{{ relatedAcc.type == 'email' ? langText.SHEIN_KEY_PWA_21279 : langText.SHEIN_KEY_PWA_21301 }}</p>
      <p class="alias">
        {{ relatedAcc.selected.alias_type == '1' ? relatedAcc.selected.alias : `+${relatedAcc.selected.area_code} ${relatedAcc.selected.alias}` }}
      </p>
    </div>
    <div
      v-else
      class="free"
    >
      <p>{{ langText.SHEIN_KEY_PWA_21286 }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'RelatedAccountTip',
  computed: {
    ...mapState('login', ['langText', 'relatedAcc']),
  }
})
</script>

<style lang="less">
.page__login_related-accTips {
    padding: 0 24px;
    margin: 0 0 16px 0;
    & > div {
        background: #FAFAFA;
        padding: 12px 24px;
        text-align: center;
    }
    p {
        color: @sui_color_gray_dark2;
    }
    p.alias {
        .font-dpr(36px);
        font-weight: bold;
        color: #222222;
    }
}
</style>
