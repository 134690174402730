<template>
  <div class="bottom-banner_container">
    <div
      class="bottom-banner_item"
      @click="handleNextPage('point')"
    >
      <MaskGroupIcon :class="bannerBgClass" />
      <div
        class="bottom-banner_text"
        :style="{paddingLeft: `${PointsPadding}px`}"
      >
        <div ref="PointsRef">
          {{ langText?.SHEIN_KEY_PWA_29182 }}
        </div>
      </div>
    </div>
    <div
      class="bottom-banner_item"
      @click="handleNextPage('return')"
    >
      <ReturnIcon :class="bannerBgClass" />
      <div
        class="bottom-banner_text"
        :style="{paddingLeft: `${ReturnPadding}px`}"
      >
        <div ref="ReturnRef">
          {{ langText?.SHEIN_KEY_PWA_29183 }}
        </div>
      </div>
    </div>
    <div
      class="bottom-banner_item"
      @click="handleNextPage('tab')"
    >
      <ArrivalsIcon :class="bannerBgClass" />
      <div
        class="bottom-banner_text"
        :style="{paddingLeft: `${ArrivalsPadding}px`}"
      >
        <div ref="ArrivalsRef">
          {{ langText?.SHEIN_KEY_PWA_29184 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import MaskGroupIcon from './Icon/MaskGroupIcon'
import ReturnIcon from './Icon/ReturnIcon'
import ArrivalsIcon from './Icon/ArrivalsIcon'
const { langPath, host, SiteUID } = gbCommonInfo

defineProps({
  langText: {
    type: Object,
    default: () => {}
  }
})

const PointsRef = ref(null)
const ReturnRef = ref(null)
const ArrivalsRef = ref(null)
const PointsPadding = ref()
const ReturnPadding = ref()
const ArrivalsPadding = ref()

const bannerBgClass = computed(()=> {
  if(SiteUID == 'pwar'){
    return 'flip-horizontal'
  }
  return ''
})

const handleNextPage = (type) => {
  if(!type) return
  if(type == 'point') {
    location.href = host + langPath + `/bonus-point-program-a-371.html`
  }
  if(type == 'return') {
    location.href = host + langPath + `/Return-Policy-a-281.html`
  }
  if(type == 'tab') {
    location.href = host + langPath
  }
}

const updateTextLine = () => {
  const pointHeight = PointsRef.value.clientHeight
  const returnHeight = ReturnRef.value.clientHeight
  const arrivalsHeight = ArrivalsRef.value.clientHeight
  const pointLineHeight = parseInt(window.getComputedStyle(PointsRef.value).lineHeight)
  const returnLineHeight = parseInt(window.getComputedStyle(ReturnRef.value).lineHeight)
  const arrivalsLineHeight = parseInt(window.getComputedStyle(ArrivalsRef.value).lineHeight)
  const pointNumberOfLines = Math.round(pointHeight / pointLineHeight)
  const returnNumberOfLines = Math.round(returnHeight / returnLineHeight)
  const arrivalsNumberOfLines = Math.round(arrivalsHeight / arrivalsLineHeight)
  if(pointNumberOfLines == '1'){
    PointsPadding.value = 0
  }
  if(returnNumberOfLines == '1'){
    ReturnPadding.value = 0
  }
  if(arrivalsNumberOfLines == '1'){
    ArrivalsPadding.value = 0
  }
}

onMounted(() => {
  setTimeout(() => {
    updateTextLine()
  }, 0)
})
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BottomBanner'
})
</script>

<style lang="less">
@vw: 375/100vw;
 .bottom-banner_container{
    margin-top: 12px;
    width: 100%;
    height: 48 / @vw;
    display: flex;
    gap: 2%;
  .flip-horizontal{
    transform: scaleX(-1);
  }
  }
  .bottom-banner_item{
    position: relative;
    width: 32%;
    height: 100%;
    border-radius: 4 / @vw;
    background: linear-gradient(136deg, rgba(255, 255, 255, 0.50) 1.72%, #F6F6F6 100%), #F6F6F6;
    .bottom-banner_text{
      padding-left: 10px;
      position: absolute;
      left: 0;
      top: 0;
      width: 80 / @vw;
      height: 48 / @vw;
      color: #666;
      font-size: 10 / @vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div{
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-align: center; 
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>
