<template>
  <div
    v-if="isShow"
    class="coupons-container"
  >
    <LeafletCoupon v-if="isOneCoupon" />
    <MultipleCoupon v-if="!isOneCoupon" />
    <div class="coupons-content">
      <template v-if="isOneCoupon">
        <div class="coupons-content_one">
          <div class="coupons-content_top">
            <template v-if="type != 0">
              <div class="coupons-content_oneTop">
                {{ discountText }}
                <span>{{ langText.SHEIN_KEY_PWA_27186 }}</span>
              </div>
              <span
                class="coupons-content_bottom"
              >{{ thresholdText }}</span>
            </template>
            <template v-if="type == 0">
              <span class="coupons-content_free">{{ langText.SHEIN_KEY_PWA_27139 }}</span>
              <span
                class="coupons-content_isbottom"
              >{{ thresholdText }}</span>
            </template>
          </div>
        </div>
      </template>
      <template v-if="!isOneCoupon">
        <div class="coupons-content_many">
          <template v-if="type != 0">
            <span
              class="coupons-content_roll"
            >{{ denomination }}</span>
            <span class="coupons-content_num">{{ couponMultipleText }}</span>
          </template>
          <template v-if="type == 0">
            <span
              class="coupons-content_rollFree"
            >{{ denomination }}</span>
            <span class="coupons-content_rollNum">{{ couponMultipleText }}</span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { template } from '@shein/common-function'
import LeafletCoupon from './icon/LeafletCoupon'
import MultipleCoupon from './icon/MultipleCoupon'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const isShow = computed(() => {
  if (Object.keys(props.data).length === 0) {
    return false
  }
  return true
})

const langText = computed(() => {
  return window.loginLanguage || window.LoginPageLangText || {}
})
const isOneCoupon = computed(() => {
  const couponMultiple = props.data?.couponData?.length
  return couponMultiple > 1 ? false : true 
})
const couponData = computed(() => {
  return props.data?.couponData?.[0]
})
const type = computed(() => {
  return couponData.value?.benifitType
})
const discountText = computed(() => {
  return couponData.value?.maxValue
})
const thresholdText = computed(() => {
  const threshold = couponData.value?.threshold
  return template(threshold, langText.value.SHEIN_KEY_PWA_27187)
})
const denomination = computed(() => {
  return type.value == 0 ? langText.value.SHEIN_KEY_PWA_27139 : discountText.value
})
const couponMultipleText = computed(() => {
  const couponMultiple = props.data?.couponData?.length
  return template(couponMultiple, langText.value.SHEIN_KEY_PWA_27194)
}) 
// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CouponsVue',
})
</script>

<style lang="less" scoped>
.flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
 .coupons-content{
  position: absolute;
  top: 30px;
  width: 100%;
  text-align: center;
  color: #FF6F46;
  .coupons-content_oneTop{
     width: 150px;
    height: 40px;
    transform: translateY(-2px);
    font-size: 24px;
    font-weight: 700;
    gap: 8px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      font-size: 12px;
      transform: translateY(4px);
    }
  }
  .coupons-content_free {
    display: block;
    width: 150px;
    height: 40px;
    margin: 0 auto;
    transform: translateX(2px);
    transform: translateY(-6px);
    font-size: 14px;
    font-weight: 700;
    .flex-center();
  }
  .coupons-content_isbottom{
    display: block;
    width: 150px;
    height: 40px;
    margin: 0 auto;
    transform: translateX(2px);
    transform: translateY(-6px);
    .flex-center();
    font-size: 10px;
    font-weight: 700;
    color: @sui_color_discount_dark;
  }
  .coupons-content_bottom{
    display: block;
    width: 150px;
    height: 40px;
    margin: 0 auto;
    .flex-center();
    transform: translateY(-6px);
    font-size: 10px;
    font-weight: 700;
    color: @sui_color_discount_dark;
  }
  &_many{
    width: 92px;
    height: 105px;
    margin: 0 auto;
  }
  &_roll{
    display: block;
    transform: translateY(14px);
    font-size: 20px;
    font-weight: 700;
    color: @sui_color_discount;
  }
  &_num{
    display: block;
    width: 100%;
    height: 60px;
    transform: translateY(22px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: @sui_color_white;
  }
  &_rollFree{
    width: 100%;
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    transform: translateY(10px);
    color: @sui_color_discount;
    .line-camp()
  }
  &_rollNum{
    display: block;
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(10px);
    font-size: 14px;
    font-weight: 700;
    color: @sui_color_white;
  }
 
}
</style>
