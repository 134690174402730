
<template>
  <s-drawer
    class="c-login-forget-pass-container"
    :visible="forgetPassword.show"
    :append-to-body="true"
    size="80rem"
  >
    <div class="c-login-forget-pass j-forget-pass">
      <a
        href="javascript:;"
        class="iconfont"
        :class="WEB_CLIENT == 'shein' ? 'icon-close-Popup' : 'icon-close'" 
        @click="closePasswordPanel"
      ></a>
      <div
        v-show="!isSendEmailSuc"
        class="head"
      >
        <h4>{{ langText.SHEIN_KEY_PWA_17548 }}</h4>
      </div>
      <!-- 手机号码密码忘记 -->
      <div
        v-if="forgetPassword.type == 'phone'"
        class="phone-content page-login__phoneItemCon page-login__phoneForget"
      >
        <p class="subTitle">
          {{ langText.SHEIN_KEY_PWA_14764 }}
        </p>
        <div
          class="input-area"
          :class="phoneTip.show ? 'error' : ''"
        >
          <label for="register-phoneInput">{{ langText.SHEIN_KEY_PWA_17715 }}</label>
          <div class="input-filed">
            <span
              class="country-area"
              @click="handleAreaClick"
            >{{ areaCode.abbr }} +{{ areaCode.code }}<i class="suiiconfont sui_icon_more_s_triangle_down_12px"></i></span>
            <input
              id="register-phoneInput"
              v-model="phone"
              type="text"
              :placeholder="langText.SHEIN_KEY_PWA_17075"
              @input="handlePhoneChange"
              @blur="handlePhoneBlurCheck"
            />
          </div>
          <p
            v-show="phoneTip.show"
            class="tip"
            @click="toSignup"
            v-html="phoneTip.txt"
          ></p>
        </div>
        <div
          class="input-area"
          :class="codeTip.show ? 'error' : ''"
        >
          <label for="registerPhoneCode">{{ langText.SHEIN_KEY_PWA_17724 }}</label>
          <div class="input-filed">
            <input
              id="registerPhoneCode"
              v-model="code"
              type="text"
              :placeholder="langText.SHEIN_KEY_PWA_18691"
              @input="handleCodeInputChange"
            />
            <s-button
              v-show="codeDownTime <= 0"
              class="code-btn"
              :type="['primary', 'H48PX']"
              @click="handleSendCode('forget_msg_verify')"
            >
              {{ codeIsSended ? langText.SHEIN_KEY_PWA_14897 : langText.SHEIN_KEY_PWA_17205 }}
            </s-button>
            <s-button
              v-show="codeDownTime > 0"
              class="code-btn"
              disabled
              :type="['primary', 'H48PX']"
            >
              {{ codeDownTime }}S
            </s-button>
          </div>
          <p
            v-show="codeTip.show"
            class="tip"
          >
            {{ codeTip.txt }}
          </p>
        </div>
        <s-button
          class="forget-btn"
          width="100%"
          :type="['primary', 'H88PX']"
          @click="handlePhoneForget"
        >
          {{ langText.SHEIN_KEY_PWA_14896 }}
        </s-button>
        <div class="page-login__resetType">
          <a
            v-if="codeIsSended"
            class="unacceptable"
            href="javascript:void(0)"
            @click="showUnacceptableTip(true)"
          >{{ langText.SHEIN_KEY_PWA_22719 }}</a>
          <a
            href="javascript:void(0)"
            da-event-click="2-8-25"
            @click="handleResetType('email')"
          >{{ langText.SHEIN_KEY_PWA_14634 }}</a>
        </div>
      </div>
      <!-- 邮箱密码忘记 -->
      <div
        v-show="!isSendEmailSuc"
        v-else
        class="content"
      >
        <p v-if="commonAbt.EmailForgetPasswordVerify != 'yes'">
          {{ inputForgetSendEmail ? langText.SHEIN_KEY_PWA_17549 : langText.SHEIN_KEY_PWA_17550 }}
        </p>
        <p
          v-if="commonAbt.EmailForgetPasswordVerify == 'yes'"
          style="margin-top: 6px;"
        >
          {{ langText.SHEIN_KEY_PWA_34577 }}
        </p>
        <div
          class="input-area"
          :class="{ 'input-focus' : isFocusInput || inputForgetSendEmail.length, 'input-error': forgetSendEmailTip.show } "
        >
          <label for="forgetInputId">
            <p class="label-title">{{ langText.SHEIN_KEY_PWA_15580 }}</p>
            <input
              id="forgetInputId"
              v-model="inputForgetSendEmail"
              type="text"
              @blur="handleBlur"
              @focus="focusInputFun"
            />
          </label>
          <div
            v-show="!isReturnFailed"
            class="error-tips"
            :class="errorTipStyle"
            @click="toSignup"
            v-html="forgetSendEmailTip.txt"
          >
          </div>
          <div
            v-show="isReturnFailed"
            class="error-tips"
            @click="toSignup"
            v-html="(langText.SHEIN_KEY_PWA_18611 || '')?.replace('@click={0}', '')"
          ></div>
        </div>
        <EmaiCodeInput
          v-if="commonAbt.EmailForgetPasswordVerify == 'yes'"
          ref="emailCodeInputRef"
          :forgetSendEmailTip="forgetSendEmailTip"
          :email="inputForgetSendEmail"
          :langText="langText"
        />
        <s-button
          width="100%"
          :type="['primary', 'H88PX']"
          :style="{ textTransform: 'uppercase', marginTop: commonAbt.EmailForgetPasswordVerify == 'yes' ? '20px' : ''}"
          @click="handleForgetSubmit"
        >
          {{ langText.SHEIN_KEY_PWA_15576 }}
        </s-button>
        <div
          v-if="!forgetPassword.noPhone"
          class="page-login__resetType"
        >
          <a
            v-if="forgetPassword.type == 'email' && phoneAbt.resetPasswordViaSMS == 'on'"
            href="javascript:void(0)"
            @click="handleResetType('phone')"
          >{{ langText.SHEIN_KEY_PWA_18713 }}</a>
        </div>
      </div>
      <s-drawer
        class="c-forget-send-sucess"
        :visible="isSendEmailSuc"
        size="90rem"
        max-size="90%"
        :append-to-body="true"
      >
        <div class="send-success">
          <a
            href="javascript:;"
            class="close iconfont"
            :class="WEB_CLIENT == 'shein' ? 'icon-close-Popup' : 'icon-close'"
            @click="handleCloseSuccessPanel"
          ></a>
          <div class="icon-space">
            <i
              class="iconfont"
              :class="WEB_CLIENT == 'shein' ? 'icon-success-copy' : 'icon-success1'"
            ></i>
          </div>
          <h6>{{ langText?.SHEIN_KEY_PWA_18581?.replace('{0}', inputForgetSendEmail) }}</h6>
          <p>{{ langText.SHEIN_KEY_PWA_18582 }}</p>
          <p>{{ langText.SHEIN_KEY_PWA_18583 }}</p>
          <p
            @click="handleTryAgain"
            v-html="langText?.SHEIN_KEY_PWA_18584?.replace('{0}', 'javascript:void(0);')"
          ></p>

          <button
            class="mshe-btn-black mshe-btn-block"
            @click="closePasswordPanel"
          >
            {{ langText.SHEIN_KEY_PWA_17539 }}
          </button>
          <span
            class="resend-link"
            @click="handleForgetSubmit"
          >{{ langText.SHEIN_KEY_PWA_15784 }}</span>

          <!-- 新增在线问答入口按钮 -->
          <span 
            v-if="commonAbt.forgetPwdQA" 
            class="online-question"
            @click="handleVerify"
          >
            {{ langText.SHEIN_KEY_PWA_22727 }}
          </span>

          <div
            class="customer-service j-online-btn-root"
            @click="openCustomerService"
          >
            <i class="suiiconfont sui_icon_me_support_24px"></i>
            <span>{{ langText.SHEIN_KEY_PWA_15610 }}</span>
          </div>
        </div>
      </s-drawer>
      <s-drawer
        :visible="isShowUnacceptableTip"
        :append-to-body="true"
        class="tip-block"
      >
        <unacceptable-tip 
          :alias="phone"
          :riskVerify="riskVerify"
          @close="showUnacceptableTip" 
        />
      </s-drawer>
      <phone-password-change />
      <ResetPasswordModal 
        @close="closePasswordPanel"
      />
    </div>
  </s-drawer>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { checkEmail, formatErrorLoginInput, formatNormalErrorMessage } from '../../util'
import { forgetPasswordEmailSer, phoneForgetSer } from '@login/service'
import { PhoneMixin } from '../../mixin/index'
import { mapMutations, mapState } from 'vuex'
import EmaiCodeInput from './EmailResetPassword/EmaiCodeInput.vue'
import PhonePasswordChange from './PhonePasswordChange.vue'
import ResetPasswordModal from './EmailResetPassword/ResetPasswordModal.vue'
import UnacceptableTip from './UnacceptableTip.vue'
import { template, patternEmail,  } from '@shein/common-function'
import { saSend } from '../../analysis/util'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { robotLinkCommon } from 'public/src/pages/common/utils/index.js'
import { initRiskVerify } from '@login/common/index.js'
import { exposeCannotAcceptCode, clickCannotAcceptCode, exposeCannotAcceptCodePop, clickOtherSolutioins } from 'public/src/pages/login/utils/loginAnalysis.js'

const { lang, PUBLIC_CDN, IMG_LINK, host, langPath, WEB_CLIENT } = gbCommonInfo

export default defineComponent({
  name: 'ForgetPassword',
  components: {
    SButton,
    SDrawer,
    PhonePasswordChange,
    EmaiCodeInput,
    ResetPasswordModal,
    UnacceptableTip
  },
  mixins: [PhoneMixin],
  props: {
    from: { type: String, default: '' },
  },
  data: () => ({
    lang,
    PUBLIC_CDN,
    IMG_LINK,
    WEB_CLIENT,
    riskVerify: null,
    isFocusInput: false,
    isSendEmailSuc: false,
    isShowUnacceptableTip: false,
    isReturnFailed: false, // 接口返回无效的邮箱格式
    forgetContinuing: false,
    forgetSendEmailTip: {
      show: false,
      txt: ''
    },
    inputForgetSendEmail: '',
    phone: '',
    phoneTip: { show: false, txt: '' },
    code: '',
    codeTip: { show: false, txt: '' },
  }),
  computed: {
    ...mapState('login', ['langText', 'forgetPassword', 'phoneAbt', 'phoneLoginCodeMode', 'relatedAcc', 'instancename', 'commonAbt']),
    errorTipStyle () {
      return this.commonAbt.EmailForgetPasswordVerify == 'yes' ? 'email__send-error-tips' : ''
    },
  },
  watch: {
    forgetPassword: {
      handler(v){
        if (v.type == 'email') {
          this.inputForgetSendEmail = v.email || ''
        } 
        if (v.type == 'phone') {
          this.phone = v.phone
        }
      },
      immediate: true
    },
    codeIsSended: {
      handler(flag){
        if(flag){
          exposeCannotAcceptCode()
        }
      }, immediate: true
    }
  },
  mounted () {
    this.$evt.on('update-forget-password-info', (data) => {
      const { email, phone, instancename } = data
      if (instancename != this.instancename) return
      if (email) this.inputForgetSendEmail = email
      if (phone) this.phone = phone
    })
    setTimeout( async() => {
      this.riskVerify = await initRiskVerify()
    }, 300)
  },
  methods: {
    ...mapMutations('login', ['setPhonePasswordChange', 'setForgetPassword', 'changeDialogs']),
    template,
    closePasswordPanel () {
      if (this.isSendEmailSuc) {
        this.isSendEmailSuc = false
      }
      if (this.forgetPassword.email == '') {
        this.inputForgetSendEmail = ''
      }
      if(this.commonAbt.EmailForgetPasswordVerify == 'yes'){
        setTimeout(() => {
          this.$refs.emailCodeInputRef.emailCode = ''
        }, 0)
      }
      this.$emit('close', { origin: this.forgetPassword.from })
      this.$evt.emit('click-forget-password-close')
    },
    focusInputFun () {
      this.isFocusInput = true
      this.forgetSendEmailTip.show = false
      const email = this.inputForgetSendEmail.trim()
      this.$evt.emit('focus-forget-input', patternEmail({ email }))
    },
    handleBlur () {
      this.isFocusInput = false
      const email = this.inputForgetSendEmail.trim()
      checkEmail(email, this.forgetSendEmailTip)
    },
    toSignup () {
      this.$emit('to-signup', { email: this.inputForgetSendEmail.trim() })
    },
    // 点击忘记密码按钮
    handleForgetSubmit () {
      const email = this.inputForgetSendEmail.trim()
      // this.$evt.emit('click-forget-submit-btn')
      // 检查邮箱合法性
      if (!checkEmail(email, this.forgetSendEmailTip)) {
        const msg = formatErrorLoginInput(email, '', true)
        saSend('2-8-109', { type: 'email', result_reason: msg })
        return
      }
      const params = {
        email
      }
      const EmailForgetPasswordVerify = this.commonAbt.EmailForgetPasswordVerify || ''
      if(EmailForgetPasswordVerify == 'yes'){
        const Obj = {
          verification_code: this.$refs?.emailCodeInputRef?.emailCode || '',
          alias_type: 1
        } 
        Object.assign(params, Obj)
        if(!this.$refs?.emailCodeInputRef?.handleCheckInputCode()){
          return
        }
      }
      this.forgetContinuing = true

      this.requestForgetPassword(params)
    },
    async requestForgetPassword (params) {
      const daId = this.isSendEmailSuc ? '2-8-110' : '2-8-109'
      params.verification_code && this.$evt.emit('event-loading', true)
      const res = await geetestChallenge(forgetPasswordEmailSer, params, 'send_message')
      if (!res) {
        this.forgetContinuing = false
        this.forgetSendEmailTip.show = true
        this.forgetSendEmailTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.forgetContinuing = false
        this.forgetSendEmailTip.show = true
        this.forgetSendEmailTip.txt = this.langText.SHEIN_KEY_PWA_16274
        this.$evt.emit('event-loading', false)
        saSend(daId, { type: 'email', result_reason: 'geetest_error' })
        return
      }
      if (status == 'close') {
        this.forgetContinuing = false
        this.$evt.emit('event-loading', false)
        return
      }
      const data = apiResData || {}

      const { code, tips } = data

      this.forgetContinuing = false
      this.$evt.emit('event-loading', false)

      saSend(daId, { type: 'email', code })

      if (code == 0) {
        if(params?.verification_code){
          this.changeDialogs({
            key: 'ResetPasswordModal',
            val: { show: true, code: params?.verification_code, alias: params?.email }
          })
          return
        }
        if (this.isSendEmailSuc) { // 来自重新发送邮件链接
          SToast(this.langText.SHEIN_KEY_PWA_17551)
          return
        }
        this.isSendEmailSuc = true
        return
      }
      if(code == -415) {
        const str = this.langText?.SHEIN_KEY_PWA_18611?.replace('@click={0}', '')
        this.showInputTips(this.forgetSendEmailTip, str || data?.msg || this.langText.SHEIN_KEY_PWA_14899)
        return
      }

      if (code == 400505) {
        if (this.relatedAcc.global) { // 来自关联账号忘记密码
          this.forgetSendEmailTip.show = true
          this.forgetSendEmailTip.txt = this.langText.SHEIN_KEY_PWA_21594
          return
        }
        
        this.isReturnFailed = true
        this.forgetSendEmailTip.show = true
        return
      }
      const errTip = formatNormalErrorMessage(data)
      if(params.verification_code){
        const emailCodeTip = this.$refs?.emailCodeInputRef?.emailCodeTip
        this.showInputTips(emailCodeTip, tips || errTip || this.langText.SHEIN_KEY_PWA_14899)
        return
      }

      if (this.isSendEmailSuc) { // 来自重新发送邮件链接
        SToast(errTip)
        return
      }
      this.forgetSendEmailTip.show = true
      this.forgetSendEmailTip.txt = errTip
      
    },
    openCustomerService () {
      location.href = robotLinkCommon()
    },
    handleCloseSuccessPanel () {
      this.isSendEmailSuc = false
    },
    handleTryAgain (e) {
      if (e.target.nodeName == 'A') this.handleCloseSuccessPanel()
    },
    handlePhoneForget () {
      const { phone: alias, code: verification_code } = this
      const { code: area_code, abbr: area_abbr } = this.areaCode
        
      // 参数检查
      if (!this.handlePhoneCheckInputParams()) return
        
      const params = {
        alias_type: 2,
        alias,
        verification_code,
        area_code: area_code,
        area_abbr,
      }
      this.requestPhoneForget(params)
    },
    async requestPhoneForget (params) {
      this.$evt.emit('event-loading', true)
      // 极验验证

      const res = await geetestChallenge(phoneForgetSer, params, '')
      if (!res) {
        this.$evt.emit('event-loading', false)
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_16274)
        this.$evt.emit('event-loading', false)
        return
      }
      if (status == 'close') {
        this.$evt.emit('event-loading', false)
        return
      }
      const data = apiResData || {}
      const { code, tips } = data

      this.$evt.emit('event-loading', false)

      if (code == 0) {
        this.setPhonePasswordChange({ show: true, alias: this.phone, code: this.code })
        window?.sa?.('send', { activity_name: 'expose_reset_password_save' })
        return
      }
      
      if (code == -410) { // 手机号码格式错误
        this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_18690)
        return
      }

      if (code == -411) { // 手机号码格未注册
        if (this.relatedAcc.global) return this.showInputTips(this.phoneTip, this.langText.SHEIN_KEY_PWA_21594)
        const str = this.langText?.SHEIN_KEY_PWA_18611?.replace('@click={0}', '')
        this.showInputTips(this.phoneTip, str)
        return
      }

      this.showInputTips(this.codeTip, tips || this.langText.SHEIN_KEY_PWA_14899)
    },
    handleResetType (type) {
      this.setForgetPassword({ type })
    },
    showUnacceptableTip(flag = false){
      this.isShowUnacceptableTip = flag
      if(flag){
        clickCannotAcceptCode()
        exposeCannotAcceptCodePop()
      }
    },
    async handleVerify(){
      clickOtherSolutioins()
      const params = {
        validate_type: 'qa_online',
        validate_scene: 'forgotPassword',
        validate_direction: `${host + langPath}/user/reset_password`,
        validate_param: {
          alias: this.inputForgetSendEmail,
          alias_type: 1,
          area_code: '',
        }
      }
      try {
        const { isSuccess, code, info } = await this.riskVerify?.doVerify(params)
        if(isSuccess && info){
          window.location.href = info
          return
        }else if(code === '9001'){
          SToast(info, 1000)
          return
        }
        SToast(this.langText?.SHEIN_KEY_PWA_14899, 1000)
      }catch (err){
        SToast(this.langText?.SHEIN_KEY_PWA_14899, 1000)
      }
    }
  }
})
</script>


<style lang="less">
/* stylelint-disable declaration-no-important */
.page-login {
  &__resetType {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 24px 0;
    font-family: "SF Pro";
    a {
      color: #2D68A8;
      text-decoration: none;
    }
    .unacceptable{
      .font-dpr(24px);
      font-weight: 400;
      margin-bottom: 12px;
    }
  }
  &__phoneForget {
    .subTitle {
      color: #666;
      text-align: center;
      line-height: 1.2;
      padding-bottom: 20px;
    }
    .forget-btn {
      margin-top: 12px;
    }
  }
}
/* stylelint-disable selector-max-specificity,selector-max-type,selector-class-pattern  */
.c-login-forget-pass {
  .icon-close-Popup, .icon-close {
    color: #999;
    font-size: 20px;
    position: absolute;
    top: 14px;
    .right(14px);
    text-decoration: none;
    z-index: @zindex-hack;
  }
  .icon-close {
    font-size: 12px;
    right: 8px;
  }
  .head {
    position: relative;
    h4 {
      padding: 30px 0 12px 0;
      color: #222;
      .font-dpr(36px);
      text-align: center;
    }
  }
  .content {
    padding: 0 24px;
    > p {
      color: #767676;
      .font-dpr(28px);
      text-align: center;
      padding-bottom: 24px;
    }
    .input-area {
      border-bottom: 1px solid #e5e5e5;
      &.input-error {
        border-color: #BF4123;
      }
      label {
        color: #999999;
        .font-dpr(28px);
        position: relative;
        padding-top: 17px;
        display: block;
        .label-title {
          position: absolute;
          .left(0);
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.1s linear;
          text-transform: uppercase;
          color: #767676;
        }
        input {
          border: 0;
          width: 100%;
          line-height: 1.1;
          padding: 9px 0;
          color: #222;
        }
      }
      .error-tips {
        color: #BF4123;
        line-height: 1.2;
        padding: 0.12rem 0;
        display: none;
        a {
          color: #1860A7;
          text-decoration: none;
        }
      }
      .email__send-error-tips{
        a {
          color: #666 !important;
          text-decoration: underline !important;
        }
      }
      &.input-focus {
        label .label-title {
          top: 0;
          transform: translateY(0);
          font-size: 12px;
        }
      }
      &.input-error {
        label input {
          border-color: #BF4123;
        }
        .error-tips {
          display: block;
        }
      }
    }
    button {
      margin-top: 40px;
    }
  }
}
.c-forget-send-sucess {
  .send-success {
    padding: 0 24px;
    .close {
      position: absolute;
      top: 5px;
      .right(10px);
      font-size: 20px;
      color: #666;
      text-decoration: none;
    }
    .icon-space {
      padding: 50px 0 0 0;
      text-align: center;
      [class*="iconfont"] {
        font-size: 75px;
        color: @sui_color_safety;
      }
    }
    h6 {
      color: #222;
      line-height: 1.2;
      .font-dpr(36px);
      font-weight: bold;
      padding-bottom: 30px;
      text-align: center;
      text-transform: inherit;
    }
    p {
      color: #666666;
      line-height: 1.5;
      .font-dpr(28px);
      .padding-l(14px);
      position: relative;
      margin-bottom: 12px;
      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #CCCCCC;
        position: absolute;
        top: 7px;
        .left(0);
      }
      a {
        color: @sui_color_link;
        text-decoration: none;
      }
    }

    button {
      margin: 28px 0 12px 0;
    }
    .resend-link {
      color: @sui_color_link;
      .font-dpr(28px);
      display: block;
      text-align: center;
    }
    .online-question{
      color: @sui_color_link;
      .font-dpr(28px);
      display: block;
      text-align: center;
      padding: 16px 0;
    }
    .customer-service {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      text-align: center;
      color: #999999;
      [class*="iconfont"] {
        font-size: 14px;
      }
    }
  }
}
</style>
