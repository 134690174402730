<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70418 1.45992V0H0.505127V2.32253V5.14669V7.22576H1.38061C1.61999 6.38755 2.39173 5.77393 3.30679 5.77393C4.22185 5.77393 4.99359 6.38755 5.23297 7.22576H5.80847C6.04786 6.38755 6.81959 5.77393 7.73465 5.77393C8.6497 5.77393 9.42144 6.38754 9.66083 7.22574H10.2849V2.3036L7.70418 1.45992ZM7.70418 4.22558V2.51201L9.28455 3.02858V4.22558H7.70418Z"
      fill="white"
    />
    <circle
      cx="7.7583"
      cy="7.77686"
      r="1"
      fill="white"
    />
    <circle
      cx="3.30688"
      cy="7.77686"
      r="1"
      fill="white"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FreeShippingIcon'
})
</script>
