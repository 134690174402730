<template>
  <LoginButton
    class="login__point_contianer"
    :type="type"
    :text="text"
    :isLoading="isLoading"
    :isDisabled="isDisabled"
    :isShowUsed="subscriptData?.showUsed"
    :style="{ marginTop: isShowSubscript ?`${compatibleTop}px` : '' }"
    @click="handleClick"
  >
    <template #extra>
      <!-- <ClientOnly> -->
        <template v-if="subscriptData">
          <span
            v-if="subscriptData?.showType == 'A' || isWhtasApp"
            class="used"
          >{{ langText.SHEIN_KEY_PWA_22297 }}</span>
          <template v-if="subscriptData?.showType == 'B' && isShowSubscript">
            <!-- <div
              ref="subscriptRef"
              :class="['point-container', 'point__animation', !isContinue ? 'btn__animation' : '']"
              :style="{ top: `-${subscriptTop}px`, background: `${subscriptData?.subscriptType?.background}` }"
            > 
              <div class="point-animation"></div>
              <div class="point-icon">
                <CouponsIcon v-if="isComponent == 'coupons'" />
                <ActivityIcon v-if="isComponent == 'promotion'" />
                <FreeShippingIcon v-if="isComponent == 'freeShipping'" />
                <SingleCouponIcon v-if="isComponent == 'singleCoupon'" />
              </div>
              <p>
                {{ subscriptData?.subscriptType?.text }}
              </p>
            </div> -->
            <!-- <div
              class="top-triangle"
              :style="{ borderTopColor: `${subscriptData?.subscriptType?.borderTopColor}` }"
            ></div> -->
            <span
              v-if="subscriptData?.showUsed"
              class="last-used"
            >
              {{ langText.SHEIN_KEY_PWA_30020 }}
            </span>
          </template>
        </template>
      <!-- </ClientOnly> -->
    </template>
  </LoginButton>
</template>

<script setup>
import { ref, computed, onMounted, watch, onBeforeUnmount, watchEffect } from 'vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import LoginButton from './LoginButton'
import CouponsIcon from './Icon/CouponsIcon'
import ActivityIcon from './Icon/ActivityIcon'
import FreeShippingIcon from './Icon/FreeShippingIcon.vue'
import SingleCouponIcon from './Icon/SingleCouponIcon.vue'
import { formatSubscript } from './util'
import { useStore } from '@login/hooks/utils.js'
import { ClientOnly } from '@sheinfe/vue-client-only'

const props = defineProps({
  type: { type: Array, default: () => ['primary'] },
  text: { type: String, default: '' },
  isLoading: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  langText: { type: Object, default: () => {} },
  isUsed: { type: Boolean, default: false },
  isWhtasApp: { type: Boolean, default: false },
  configData: { type: Object, default: () => {} },
  isContinue: { type: Boolean, default: false },
  isShowSubscript: { type: Boolean, default: true },
})
const emit = defineEmits(['click'])
const store = useStore()
// const toast = useToast()

const subscriptRef = ref(null)
const subscriptTop = ref(13)
const compatibleTop = ref(0)

const LoginButtonAnimation = computed(() => store.state.login.LoginButtonAnimation)
const newUIStates = computed(() => store.state.login.newUIStates)
const showIndex = computed(() => store.state.login.showIndex)

const subscriptData = computed(() => {
  const { configData, isUsed, isContinue, langText } = props
  if(!configData.type && isUsed){
    const returnData = { showType: 'A' }
    return returnData
  }
  if(configData.type && !isUsed){
    const subscriptType = formatSubscript({ isContinue, langText, configData })
    const returnData = { showType: 'B', showUsed: false, subscriptType }
    return returnData
  }
  if(configData.type && isUsed) {
    const subscriptType = formatSubscript({ isContinue, langText, configData })
    const returnData = { showType: 'B', showUsed: true, subscriptType }
    return returnData
  }
  return false
})

watch(showIndex, (val)=> {
  if(val){
    subscriptRef.value?.classList.add('point__animation')
  }
  if(!val){
    store.commit('login/assignState', { LoginButtonAnimation: [] })
    subscriptRef.value?.classList.add('point__animation')
    store.commit('login/setLoginButtonPoint',  {
      type: '',
      promotion_price: '',
      couponData: {},
    })
  }
})

watchEffect(() => {
  const textHeight = subscriptRef.value?.clientHeight
  if (newUIStates.value.showType == 'continue' && textHeight > 22){
    compatibleTop.value = 8
    subscriptTop.value = 22
  }
})

const updateCoolingValue = () => {
  if(showIndex.value && newUIStates.value.showType == 'continue' && !LoginButtonAnimation.value?.includes('continue')) {
    const stack = ['continue']
    setAnimationStyle(stack)
  } 
}

const setAnimationStyle = (stack) => {
  store.commit('login/setLoginButtonAnimation', stack)
  subscriptRef.value?.classList.add('point__animation')
}


const updateSubscriptStyle = () => {
  const textHeight = subscriptRef.value?.clientHeight
  if(newUIStates.value.showType != 'continue' && newUIStates.value.showType != '' && textHeight > 22){
    compatibleTop.value = 8
    subscriptTop.value = 22
  }
}

const handleClick = () => {
  if (!store.state.login.online) {
    SToast(props.langText.SHEIN_KEY_PWA_31762)
    return
  }
  emit('click')
}

const handleInitData = () => {
  setTimeout(() => {
    updateSubscriptStyle()
  }, 150)
  if(showIndex.value && newUIStates.value.showType == 'continue' && newUIStates.value.mode == 'phone') {
    subscriptRef.value?.classList.remove('point__animation')
  } 
  if(showIndex.value && newUIStates.value.showType == 'continue' && LoginButtonAnimation.value?.includes('continue')){
    subscriptRef.value?.classList.remove('point__animation')
  }
  setTimeout(()=> {
    updateCoolingValue()
  }, 2050)
}

onMounted(() => {
  handleInitData()
})

onBeforeUnmount(() => {
  subscriptRef.value = null
})

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoginPointButton'
})
</script>

<style lang="less" scoped>
.login__point_contianer{
  position: relative;
  .top-triangle{
    position: absolute;
    top: 6px;
    right: -5px;
    width: 0;
    height: 0;
    border-left: .5px solid transparent;
    border-right: 5px solid transparent;
    border-top-style: solid;
    border-top-width: 5px;
  }
  .last-used{
    position: absolute;
    left: 50%;
    top: 58%;
    transform: translate(-50%, 0);
    font-size: 12px;
    color: @sui_color_white_alpha60;
    text-transform: capitalize;
  }
  .point-container{
    position: absolute;
    max-width: 75%;
    height: auto;
    min-height: 19px;
    line-height: 1;
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
    right: -5px;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
   

    .point-icon {
      min-height: 15px;
      font-size: 12px;
      margin-right: 4px;
      transform: translateY(1px);
    }
    p {
    font-size: 12px;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    }
  }
  .point__animation{
     &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.65),
          rgba(255, 255, 255, 0));
      transform-origin: center center;
      transform: translate(-100%, 50%) rotate(-90deg);
      animation-name: wipes;
      animation-duration: 2s;
      animation-iteration-count: 1;
    }
  }
   .btn__animation{
     &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.65),
          rgba(255, 255, 255, 0));
      transform-origin: center center;
      transform: translate(-100%, 50%) rotate(-90deg);
      animation-name: wipes;
      animation-duration: 2s;
      animation-iteration-count: 1;
    }
  }
  .used{
    position: absolute;
    line-height: 1;
    text-align: center;
    font-size: 10px;
    padding: 4px 6px;
    font-weight: bold;
    top: -4px;
    right: -4px;
    background: @sui_color_note_light_bg;
    border-radius: 4px;
    color: @sui_color_link;
    cursor: pointer;
    border-bottom-left-radius: 0;
  }
}
 @keyframes wipes {
  0% {
      transform: translate(-100%, 50%) rotate(-90deg);
    }
  
    100% {
      transform: translate(100%, 50%) rotate(-90deg);
    }
}
</style>
