<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 115 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      width="114"
      height="48"
      rx="4"
      fill="#F6F6F6"
    />
    <rect
      x="0.5"
      width="114"
      height="48"
      rx="4"
      fill="url(#paint0_linear_561_14698)"
    />
    <path
      opacity="0.5"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M105.096 14.0086C103.129 13.9361 101.556 12.3182 101.556 10.333L101.553 10.4709C101.481 12.4384 99.863 14.0112 97.8778 14.0112C99.9092 14.0112 101.556 15.6579 101.556 17.6893C101.556 15.6579 103.203 14.0112 105.234 14.0112L105.096 14.0086ZM94.9 14.0663C94.5 13.533 93.7 13.533 93.3 14.0663L91.3 16.733C90.9 17.2663 90.1 17.2663 89.7 16.733L87.7 14.0663C87.3 13.533 86.5 13.533 86.1 14.0663L83.7114 17.2512C83.4635 17.5817 83.4444 18.0307 83.6634 18.3811L86.1046 22.2876C86.219 22.4707 86.1929 22.7084 86.0414 22.8623C82.707 26.2971 80.2882 30.6256 79.1787 35.4541C79.1355 35.6448 79.2564 35.8342 79.4479 35.874C83.016 36.6138 86.7126 36.9997 90.5 36.9997C94.2879 36.9997 97.985 36.6137 101.551 35.8728C101.742 35.8331 101.863 35.6447 101.821 35.4541C100.745 30.7689 98.4355 26.5545 95.2529 23.1704C94.9341 22.8315 94.8746 22.3204 95.1212 21.9258L97.3366 18.3811C97.5556 18.0307 97.5365 17.5817 97.2886 17.2511L94.9 14.0663Z"
      fill="url(#paint1_linear_561_14698)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M85.3035 29.063C85.7812 28.4161 86.6525 28.3112 87.2496 28.8288C89.4422 30.729 92.5578 30.729 94.7504 28.8288C95.3475 28.3112 96.2188 28.4161 96.6965 29.063C97.1742 29.7099 97.0774 30.6538 96.4803 31.1714C93.2763 33.9482 88.7237 33.9482 85.5197 31.1714C84.9226 30.6538 84.8258 29.7099 85.3035 29.063Z"
      fill="url(#paint2_linear_561_14698)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_561_14698"
        x1="-52.508"
        y1="23.1596"
        x2="-20.1811"
        y2="102.722"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="white"
          stop-opacity="0.5"
        />
        <stop
          offset="1"
          stop-color="#F6F6F6"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_561_14698"
        x1="105.184"
        y1="26.0447"
        x2="71.8197"
        y2="20.191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#959595" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.83"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_561_14698"
        x1="96.9999"
        y1="30"
        x2="84.5359"
        y2="29.5739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0.5"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ArrivalsIcon'
})
</script>
